function Modal() {
    function render() {
        const modal = document.createElement("div");
        modal.className = "modal fade";
        modal.id = "helpModal";
        modal.setAttribute("tabindex", "-1");
        modal.setAttribute("role", "dialog");
        modal.setAttribute("aria-labelledby", "exampleModalLabel");
        modal.setAttribute("aria-hidden", "true");

        const modalDialog = document.createElement("div");
        modalDialog.className = "modal-dialog";
        modalDialog.setAttribute("role", "document");

        const modalContent = document.createElement("div");
        modalContent.className = "modal-content";

        const modalHeader = document.createElement("div");
        modalHeader.className = "modal-header";

        const modalTitle = document.createElement("h5");
        modalTitle.className = "modal-title";
        modalTitle.id = "exampleModalLabel";
        modalTitle.textContent = "Help";

        const closeButton = document.createElement("button");
        closeButton.type = "button";
        closeButton.className = "close";
        closeButton.setAttribute("data-dismiss", "modal");
        closeButton.setAttribute("aria-label", "Close");

        const closeSpan = document.createElement("span");
        closeSpan.setAttribute("aria-hidden", "true");
        closeSpan.innerHTML = "&times;";

        closeButton.appendChild(closeSpan);
        modalHeader.appendChild(modalTitle);
        modalHeader.appendChild(closeButton);

        const modalBody = document.createElement("div");
        modalBody.className = "modal-body";
        modalBody.textContent = "For support please contact support@echobolt.co.uk";

        const modalFooter = document.createElement("div");
        modalFooter.className = "modal-footer";

        const closeFooterButton = document.createElement("button");
        closeFooterButton.type = "button";
        closeFooterButton.className = "btn btn-secondary";
        closeFooterButton.setAttribute("data-dismiss", "modal");
        closeFooterButton.textContent = "Close";

        modalFooter.appendChild(closeFooterButton);

        modalContent.appendChild(modalHeader);
        modalContent.appendChild(modalBody);
        modalContent.appendChild(modalFooter);

        modalDialog.appendChild(modalContent);
        modal.appendChild(modalDialog);

        return modal;
    }

    return {
        render,
    };
}

export { Modal }