import { createSelector } from "../shared/_create_selector"
import { createSelectOptions} from "../shared/_create_select_options"
import { setSelectorSelected} from "../shared/set_selector_selected";

function createFleetSiteSelectors(fleetName, fleets) {
    const headerLeft = document.getElementById("boltwatch-header-left");
    // Check if fleet selector exists
    let fleetSelector = document.getElementById("fleet-selector");
    if (!fleetSelector) {
        fleetSelector = createSelector("fleet-selector");
        fleetSelector.classList.add("primary-selector")
        headerLeft.appendChild(fleetSelector);

        //var fleetSelectorOptionsArray = [
        //    {name: fleetName, isSelected: true}
        //]
        var fleetSelectorOptionsArray = []

        fleets.forEach(optionItem => {
            var optionName = (optionItem["name"])
            var optionID = (optionItem["id"])
            var optionHash = {name: optionName, id: optionID, isSelected: false}
                fleetSelectorOptionsArray.push(optionHash)
        })

        createSelectOptions(fleetSelector, fleetSelectorOptionsArray)
        setSelectorSelected("fleet-selector", fleetName)
    }

    // Check if site selector exists
    let siteSelector = document.getElementById("site-selector");
    if (!siteSelector) {
        siteSelector = createSelector("site-selector");
        siteSelector.classList.add("primary-selector")
        headerLeft.appendChild(siteSelector);

        var selectorSiteOptionsArray = [
            {name: "All Sites", isSelected: true}
        ]
        createSelectOptions(siteSelector, selectorSiteOptionsArray)
    }
}


export { createFleetSiteSelectors };