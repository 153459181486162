import { render } from "./render";
import {state} from "./state";

document.addEventListener('stateChange', function (e) {
    let oldState = e.detail.oldState;
    let newState = e.detail.newState;

    let stateChangeSummary = [];

    for (let key in oldState) {
        if (key === 'turbine' && JSON.stringify(oldState[key]) === JSON.stringify(newState[key])) {
            continue; // Skip if the turbine array contents haven't changed
        }

        if (oldState[key] !== newState[key]) {
            stateChangeSummary.push(key);
        }
    }

    render(stateChangeSummary);
});
