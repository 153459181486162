import { colorHex } from "./_color_hex";

function assembleSiteDataset(chartData) {
    var processedData = chartData.map(function (item) {
        return {
            id: item.id,
            name: item.name,
            lat: item.lat,
            lng: item.long,
            color: colorHex(item.color)
       };
    });

    return processedData;
}

export { assembleSiteDataset };


