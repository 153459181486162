import {state} from "./state";
import {router} from "./router";

$(document).ready(function () {
    // if in fleet overview and a site is clicked:
    map.on('click', 'fleet', (e) => {
        var level = "site";
        var id = e.features[0].properties["id"];

        // Update the URL
        router.navigate(`/fleets/${state.fleet}/sites/${id}`);
    });
});
