function clearElements(divId) {
    const divToEmpty = document.getElementById(divId);
    if (!divToEmpty) {
        return;
    }

    for (let i = divToEmpty.childNodes.length - 1; i >= 0; i--) {
        const childNode = divToEmpty.childNodes[i];
        divToEmpty.removeChild(childNode);
    }
}

export { clearElements }