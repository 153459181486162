import {addAjaxPromise} from "../shared/ajax_promise";

function fetchTimelineData(asset, ids) {
    
    const promise = new Promise((resolve, reject) => {

        // Get chart data for each inspection
        $.ajax({
            type: "POST",
            url: "/api/site_comments",
            data: JSON.stringify({ asset: asset, ids: ids }),
            cache: false,
            contentType: "application/json",
            success: function (data) {
                resolve(data);
            },
            error: function (err) {
                resolve({ error: err });
            }
        });
    });

    addAjaxPromise(promise);

    return promise;


};

export { fetchTimelineData };