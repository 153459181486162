import {assembleFleetDataset} from "../_assemble_fleet_dataset";
import {initSteppedProgress} from "../_init_stepped_progress";
import {fetchFleetTableData} from "../ajax/fetch_fleet_table_data";
import {repopulateSelectorOptions} from "../shared/_repopulate_selector_options";
import {state} from "../shared/state"
import {setSelectorSelected, setSelectorSelectedById} from "../shared/set_selector_selected";
import {router} from "../shared/router"
import {handleAjaxErrors} from "../shared/ajax_promise";

function renderFleetOverview(stateChangeSummary) {
    if (stateChangeSummary.includes("fleet") && stateChangeSummary.includes("site") && state.site != null) {
        var fleetId = state.fleet;
        fetchFleetTableData(fleetId).then((ajaxData) => {
            // render selectors
            setSelectorSelected("fleet-selector", ajaxData);
            repopulateSelectorOptions("site", ajaxData);
            setSelectorSelectedById('site-selector', state.site)
            // fleet overview table
            createFleetOverview(ajaxData["sites"]);
        }).catch(handleAjaxErrors)
    } else if (stateChangeSummary.includes("fleet")) {
        var fleetId = state.fleet;
        fetchFleetTableData(fleetId).then((ajaxData) => {
            // render selectors
            setSelectorSelected("fleet-selector", ajaxData.fleet);
            repopulateSelectorOptions("site", ajaxData);
            // fleet overview table
            createFleetOverview(ajaxData["sites"]);
        }).catch(handleAjaxErrors)
    }
}

function createFleetOverview(ajaxData) {
    var chartData = assembleFleetDataset(ajaxData);
    // Empty the site-table-canvas
    $('#site-table-canvas').empty();

    // Remove the existing table
    if ($('#siteTable').length != 0) {
        if ($.fn.DataTable.isDataTable('#siteTable')) {
            var tableToDestroy = $('#siteTable').DataTable();
            tableToDestroy.destroy();
        }
    }

    // Create a new table
    var table = $('<table id="siteTable" class="site-table mr-0 hover w-100">');
    $('#site-table-canvas').append(table);

    var tableData = chartData;

    // Initialize DataTable
    $('#siteTable').DataTable({
        paging: false,
        filter: false,
        info: false,
        order: [[6, 'asc']],
        data: tableData,
        columns: [
            { title: "&nbsp;&emsp;&emsp;", data: "blank", visible: true, orderable: false },
            {
                title: "Site Name",
                data: "name",
                orderable: false,
                render: function (data, type, row) {
                    return '<a href="#" data-site-id="' + row.id + '">' + data + '</a>';
                }
            },
            { title: "Turbines", data: "turbineCount", orderable: false },
            {
                title: "Last Inspected",
                data: "lastInspected",
                orderable: false,
                render: function (data) {
                    const date_obj = new Date(data);
                    const day = date_obj.getDate();
                    const month = date_obj.toLocaleString('en', { month: 'short' });
                    const year = date_obj.getFullYear();

                    return `${day} ${month} ${year}`;
                }
            },
            { title: "Orderable Date", data: "lastInspected", visible: false, orderable: false },
            { title: "Turbine Status", data: "connectionStatus", orderable: false, render: function (data, type, row) {
                    var div = document.createElement('div');
                    div.setAttribute('data-stepped-bar', JSON.stringify(data));
                    return div.outerHTML;
                } },
            { title: "Status Order", data: "connectionStatusOrder", visible: false, orderable: false },
            { title: "Site Id", data: "id", visible: false, orderable: false },
            { title: "", data: "blank", visible: true, orderable: false }
        ]
    });

    initSteppedProgress();

    $('#siteTable').on('click', 'a[data-site-id]', function (e) {
        e.preventDefault();
        var siteId = $(this).data('site-id');

        router.navigate(`fleets/${state.fleet}/sites/${siteId}`);
    });
}


export { renderFleetOverview };