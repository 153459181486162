import {addAjaxPromise} from "../shared/ajax_promise";

function fetchSiteOverviewTableData(siteId, selectedConnectionTypes) {
  let connectionType = null;
  if (selectedConnectionTypes != "All Connections") {
    connectionType = selectedConnectionTypes
  }

  const promise = new Promise((resolve, reject) => {

    // Get chart data for each inspection
    $.ajax({
      type: "POST",
      url: "/api/site_overview_table_data",
      data: JSON.stringify({ site: siteId, connection_type: connectionType }),
      cache: false,
      contentType: "application/json",
      success: function (data) {
        resolve(data);
      },
      error: function (err) {
        resolve({ error: err });
      }
    });
  });

  addAjaxPromise(promise);

  return promise;

};

export { fetchSiteOverviewTableData };