import { createSelector} from "../shared/_create_selector";
import { createSelectOptions } from "../shared/_create_select_options"

function createTurbineConnectionStatusSelectors() {

    const selectorDiv = document.getElementById("secondary-selectors")
    const boltTab = document.getElementById("v-pills-bolt")
    const siteTab = document.getElementById("site-tab-header")

    var selectorTurbine = createSelector("turbine-selector", "multiple", "All Turbines")
    var selectorConnection = createSelector("connection-selector")
    var selectorStatus = createSelector("status-selector")
    var selectorInspection = createSelector("inspection-selector")

    selectorDiv.appendChild(selectorTurbine);
    selectorDiv.appendChild(selectorConnection);

    //const filterByLabel = document.createElement("p");
    //filterByLabel.className = "px-4 pt-2 m-0 d-flex";
    //filterByLabel.innerText = "Filter by: ";

    let iconSite = document.createElement('i');
    iconSite.className = 'material-icons pl-4 pr-2 pt-2 m-0';
    iconSite.textContent = "filter_list";

    let iconBolt = document.createElement('i');
    iconBolt.className = 'material-icons pl-4 pr-2 pt-2 m-0';
    iconBolt.textContent = "filter_list";

    siteTab.appendChild(iconSite)
    siteTab.appendChild(selectorStatus);

    boltTab.appendChild(iconBolt)
    boltTab.appendChild(selectorInspection);

    // Connection selector
    var selectorConnectionOptionsArray = [
        {name: "Select All", isSelected: true}
    ]
    createSelectOptions(selectorConnection, selectorConnectionOptionsArray)

    // Status selector
    var selectorStatusOptionsArray = [
        {name: "-- select a status --", isSelected: false, isDisabled: true, value: "placeholder"},
        {name: "All Status Levels", isSelected: true},
        {name: "Red", isSelected: false},
        {name: "Amber", isSelected: false},
        {name: "Red or Amber", isSelected: false},
        {name: "Green", isSelected: false}
    ]
    createSelectOptions(selectorStatus, selectorStatusOptionsArray)

    // Inspection selector
    var selectorInspectionOptionsArray = [
        {name: "Latest Inspection", isSelected: true},
        // {name: "Inspection 1", isSelected: false}
    ]
    createSelectOptions(selectorInspection, selectorInspectionOptionsArray)
}

export { createTurbineConnectionStatusSelectors }