function setSelectorSelected(selectorId, selectedValue) {
    
    var select = document.getElementById(selectorId);
    for (var i = 0; i < select.options.length; i++) {
        var option = select.options[i];
        if (option.value == selectedValue) {
            option.selected = true;
        } else {
            option.selected = false;
        }
    }
}

function setMultiSelectorSelected(selectorId, selectedValues) {
    // used only for the turbine selector
    var select = $(`#${selectorId}`);

    // Convert selectedValues to an array if it's not already
    if (!Array.isArray(selectedValues)) {
        selectedValues = [selectedValues];
    }

    // Check if it is all turbines
    var allTurbinesString = document.querySelector('#turbine-selector').getAttribute('data-all-turbines');
    var allTurbines = allTurbinesString.split(',').map(Number).sort((a, b) => a - b); // Sorted numerically

    //var allTurbines = $("#turbine-selector option[value='All Turbines']").data('id').split(',').map(Number).sort();
    var sortedSelectedValues = selectedValues.sort();

    // If the selected turbines are "All Turbines", no need to proceed further
    if (selectedValues.length === 0) {
        // $('#turbine-selector')[0].sumo.reload();
        $('#turbine-selector')[0].sumo.selectAll();
    }
    else if (JSON.stringify(allTurbines) === JSON.stringify(sortedSelectedValues)) {
        //$('#turbine-selector')[0].sumo.reload();
        $('#turbine-selector')[0].sumo.selectAll();
        return;
    } if (sortedSelectedValues.length === 0) {
        return;
    } else {
        // Deselect all options in SumoSelect
        $("#turbine-selector option").prop("selected", false);
        //select[0].sumo.unSelectAll();
        // Set the selected options
        selectedValues.forEach(function (value) {
            select.find('option[data-id="' + value + '"]').prop('selected', true);
        });

        // Trigger the change event to update SumoSelect's UI
        //select[0].sumo.reload();
    }
}



function setSelectorSelectedById(selectorId, selectedId) {
    
    var select = document.getElementById(selectorId);
    for (var i = 0; i < select.options.length; i++) {
        var option = select.options[i];
        // Access the data-id attribute using option.dataset.id
        if (option.dataset.id == selectedId) {
            option.selected = true;
        } else {
            option.selected = false;
        }
    }
}


export { setSelectorSelected, setMultiSelectorSelected, setSelectorSelectedById }