function assembleFleetDataset(chartData) {
    // iterate over the chart data
    var processedData = chartData.map(function (item) {
        // Perform your operations on each item in chartData
        // For example, extracting specific fields or transforming the data
        return {
            id: item.id,
            name: item.name,
            turbineCount: item.turbineCount,
            lastInspected: item.lastInspected,
            connectionStatus: item.statusData,
            connectionStatusOrder: item.connectionStatusOrder,
            blank: "&nbsp;&emsp;&emsp;"
        };
    });

    return processedData;
}

export { assembleFleetDataset };