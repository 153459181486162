import {state} from "../../shared/state";
import {setMultiSelectorSelected} from "../../shared/set_selector_selected";

function renderTurbineSelector(stateChangeSummary) {
    var seeState = state.turbine

    if (stateChangeSummary.includes('turbine')) {
        setMultiSelectorSelected('turbine-selector', state.turbine)
        $('#turbine-selector')[0].sumo.reload();
    }
}

export {renderTurbineSelector}