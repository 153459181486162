import moment from 'moment'
import {fetchRelaxationData} from "../ajax/fetch_relaxation_data";
import {assembleRelaxationDataset} from "../site/assemble_relaxation_dataset";
import {clearElements} from "../_clear_elements";
import {state} from "../shared/state";
import {router} from "../shared/router";
import {getAllAjaxPromises, handleAjaxErrors} from "../shared/ajax_promise";
import {activateTab} from "../shared/activate_tab";

function renderRelaxationChart(turbineIds, connectionType) {
    if (connectionType === "All Connections" || connectionType === null) {
        clearElements("relaxation-chart-div");
        return;
    }

    if (turbineIds.length === 0) {
        
        if ($("#turbine-selector option[value='All Turbines']").data('id') != null) {
            var turbineIds = $("#turbine-selector option[value='All Turbines']").data('id').split(',').map(Number);
        } else {
            var siteId = state.site
        }
    }

    fetchRelaxationData(turbineIds, connectionType, siteId).then((ajaxData) => {
        var chartData = assembleRelaxationDataset(ajaxData);
        loadRelaxationChart(chartData)
    }).catch((error) => {
        // Handle the error here
        console.log("an error occurred", error);
    });


function loadRelaxationChart(chartData) {
    const multiCollapseExample2 = document.getElementById("v-pills-relaxation");
    // multiCollapseExample2.classList.add("show");

    // Check if the element already exists
    let relaxationChartDiv = document.getElementById("relaxation-chart-div");
    if (!relaxationChartDiv) {
        // If it doesn't exist, create it
        relaxationChartDiv = document.createElement("div"); // was div
        relaxationChartDiv.id = "relaxation-chart-div";
        relaxationChartDiv.className = "relaxation-chart-div";
        multiCollapseExample2.appendChild(relaxationChartDiv);
    }

    // Empty the chart div
    for (let i = relaxationChartDiv.childNodes.length - 1; i >= 0; i--) {
        const childNode = relaxationChartDiv.childNodes[i];
        relaxationChartDiv.removeChild(childNode);
    }

    // Create Header area
    var relaxationChartHeaderDiv = document.createElement("div")
    relaxationChartHeaderDiv.id = "bolt-bar-chart-header-div"
    relaxationChartHeaderDiv.className = "chart-header-div"
    var header = document.createElement("h2")
    header.innerText = "Connection Average Preload"
    var connectionTypeField = document.createElement("p")
    connectionTypeField.className = "bolt-bar-chart-sub-header"
    connectionTypeField.innerText = "Connection: " + chartData.overview.connectionType
    relaxationChartDiv.appendChild(relaxationChartHeaderDiv)
    relaxationChartHeaderDiv.appendChild(header)
    relaxationChartHeaderDiv.appendChild(connectionTypeField)

    // Create a new canvas
    var relaxationChartCanvas = document.createElement("canvas")
    relaxationChartCanvas.className = "w-100 relaxation-chart-canvas"
    relaxationChartDiv.appendChild(relaxationChartCanvas)

    // Create a new chart
    var relaxationContext = relaxationChartCanvas.getContext('2d');

    // get normaliased load values TODO: update these
    var minLoad = 50 //<%= NormalisedLoadService.normalised_thresholds["min"] %>;
    var amberLoad = 62.5// <%= NormalisedLoadService.normalised_thresholds["amber"] %>;
    var targetLoad = 75 // <%= NormalisedLoadService.normalised_thresholds["target"] %>;
    var maxLoad = 100 // <%= NormalisedLoadService.normalised_thresholds["max"] %>;

    var legendDisplay = chartData["dataSets"].length <= 3 ? true : false

    var relaxationChart = new Chart(relaxationContext, {
        type: 'scatter',
        data: {
            datasets: chartData["dataSets"]
        },
        options: {
            responsive: true,
            //maintainAspectRatio: true,
            legend: {
                display: legendDisplay,
                position: 'bottom', // Position of legend. Can be 'top', 'left', 'bottom', or 'right'
                labels: {
                    fontColor: 'white', // Color of legend labels
                    // You can include any other label options here, see docs for more info
                }
            },
            annotation: {
                annotations: [
                    {
                        borderColor: 'red',
                        borderDash: [2, 2],
                        borderWidth: 2,
                        mode: 'horizontal',
                        type: 'line',
                        value: minLoad,
                        scaleID: 'y-axis-0',
                        label: {
                            content: "Min Load",
                            enabled: true,
                            position: "bottom",
                            backgroundColor: 'transparent',                            // fontColor: '#e0e2e2',
                            fontStyle: 'normal',
                            yPadding: 3,
                            xAdjust: -17
                        }
                    },
                    {
                        borderColor: 'red',
                        borderDash: [2, 2],
                        borderWidth: 2,
                        mode: 'horizontal',
                        type: 'line',
                        value: maxLoad,
                        scaleID: 'y-axis-0',
                        label: {
                            content: "Max Load",
                            enabled: true,
                            position: "bottom",
                            backgroundColor: 'transparent',
                            // fontColor: '#e0e2e2',
                            fontStyle: 'normal',
                            yPadding: 3,
                            xAdjust: -17
                        }
                    },
                    {
                        borderColor: 'green',
                        borderDash: [2, 2],
                        borderWidth: 2,
                        mode: 'horizontal',
                        type: 'line',
                        value: targetLoad,
                        scaleID: 'y-axis-0',
                        label: {
                            content: "Target Load",
                            enabled: true,
                            position: "bottom",
                            backgroundColor: 'transparent',
                            // fontColor: '#e0e2e2',
                            fontStyle: 'normal',
                            yPadding: 3,
                            xAdjust: -17
                        }
                    },
                    {
                        borderColor: 'orange',
                        borderDash: [2, 2],
                        borderWidth: 2,
                        mode: 'horizontal',
                        type: 'line',
                        value: amberLoad,
                        scaleID: 'y-axis-0',
                        label: {
                            content: "Amber Min Load",
                            enabled: true,
                            position: "bottom",
                            backgroundColor: 'transparent',
                            // fontColor: '#e0e2e2',
                            fontStyle: 'normal',
                            yPadding: 3,
                            xAdjust: -17
                        }
                    },
                ]
            },
            scales: {
                xAxes: [{
                    type: 'linear',
                    position: 'bottom',
                    id: 'x-axis-0',

                    ticks: {
                        fontColor: '#e8e8ee',
                        userCallback: function (label, index, labels) {
                            return moment.unix(label).format("MM/DD/YY");
                        }
                    },
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: 'Inspection Date',
                        fontColor: '#e8e8ee'
                    }
                }],
                yAxes: [{
                    id: 'y-axis-0',
                    type: 'linear',
                    position: 'left',
                    ticks: {
                        display: false,
                        suggestedMax: maxLoad * 1.01,
                        suggestedMin: 0 //autoScaleOn ? null : 0
                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'Normalised Load',
                        fontColor: '#e8e8ee'
                    }
                }]
            },
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                    label: function (tooltipItems, data) {
                        var multistringText = ['Turbine: ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].turbine];
                        multistringText.push(['Connection: ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].connection]);
                        multistringText.push(['Inspection Date: ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].inspection_date]);
                        multistringText.push(['Reported Load(Kn): ' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].average_load]);
                        var damagedBolts = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].damaged_bolts;
                        if (damagedBolts == true) {
                            multistringText.push(['Damaged Bolt(s)']);
                        }
                        ;
                        return multistringText;
                    }
                }
            }
        }
    });

    $(document).ready(function () {
        relaxationChartCanvas.onclick = function (evt) {
            var activePoint = relaxationChart.getElementAtEvent(evt);
            var pointData = relaxationChart.data.datasets[activePoint[0]._datasetIndex].data[activePoint[0]._index];

            var turbineId = pointData.turbine_id;
            var connectionType = encodeURIComponent(state.connection);
            router.navigate(`fleets/${state.fleet}/sites/${state.site}/connection_type/${connectionType}/turbines/${turbineId}`);
            Promise.all(getAllAjaxPromises()).then((results) => {
                activateTab('bolt');
            }).catch(handleAjaxErrors);
        };
    });
  }

}

export { renderRelaxationChart };