function createMapDataset(chartData) {
  const geoJSONData = {
    "type": "FeatureCollection",
    "features": []
  };

  chartData.forEach((item) => {
    const feature = {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [parseFloat(item.lng), parseFloat(item.lat)]
      },
      "properties": {
        "name": item.name,
        "id": item.id,
        "color": item.color
      }
    };
    geoJSONData.features.push(feature);
  });

  return geoJSONData;
}

export { createMapDataset }