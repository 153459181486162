import {state, updateState} from "../../shared/state";
import {setSelectorSelected} from "../../shared/set_selector_selected";
import {updateSelector} from "../../shared/update_selector";
function renderStatusSelector(stateChangeSummary) {
    var status = state.status;

    if (stateChangeSummary.includes("status")) {
        var optionsToUpdate = {
            "All Status Levels": {
                selected: false,
                disabled: false
            },
            "Red": {
                selected: false,
                disabled: false
            },
            "Amber": {
                selected: false,
                disabled: false
            },
            "Red or Amber": {
                selected: false,
                disabled: false
            },
            "Green": {
                selected: false,
                disabled: false
            }
        };

        if (status === "Turbine Summary") {
            optionsToUpdate["Turbine Summary"].selected = true;
            Object.keys(optionsToUpdate).forEach(function(option) {
                if (option !== "Turbine Summary") {
                    optionsToUpdate[option].disabled = true;
                }
            });
        } else {
            optionsToUpdate[status].selected = true;
        }

        updateSelector('status', optionsToUpdate);
    }
}

export { renderStatusSelector };