// Initialize the state object
let state = {
    fleet: null,
    site: null,
    turbine: [],
    connection: "All Connections",
    status: "All Status Levels",
    inspection: null,
    tab: null,
    statusSelectedTurbines: null
};

function updateState(updates) {
    let oldState = { ...state };
    state = { ...state, ...updates };

    // Compare old and new state
    if (JSON.stringify(oldState) !== JSON.stringify(state)) {
        // Create a new event with the old and new state
        let stateChangeEvent = new CustomEvent('stateChange', {
            detail: {
                oldState: oldState,
                newState: state
            }
        });

        // Dispatch the event if the state has changed
        document.dispatchEvent(stateChangeEvent);
    }
}

export { state, updateState }
