import {renderStatusSelector} from "./selectors/render_status_selector";
import {renderConnectionSelector} from "./selectors/render_connection_selector";
import {renderInspectionSelector} from "./selectors/render_inspection_selector";
import {renderTurbineSelector} from "./selectors/render_turbine_selector";
import {renderSiteSelector} from "./selectors/render_site_selector";

function renderSelectors(statusChangeSummary) {
    renderSiteSelector(statusChangeSummary);
    renderStatusSelector(statusChangeSummary);
    renderConnectionSelector(statusChangeSummary);
    renderInspectionSelector(statusChangeSummary);
    renderTurbineSelector(statusChangeSummary);

};

export { renderSelectors }