import {addAjaxPromise} from "../shared/ajax_promise";

function fetchRelaxationData(turbineIds, connectionType, siteId) {
    const promise = new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: "/api/relaxation_chart_data",
            data: JSON.stringify({ turbines: turbineIds, connections: connectionType, site: siteId }),
            cache: false,
            contentType: "application/json",
            success: function (data) {
                resolve(data);
            },
            error: function (err) {
                resolve({ error: err });
            }

        });
    });

    addAjaxPromise(promise);

    return promise;
};

export { fetchRelaxationData }