function createSelectOptions(div, optionsArray) {
    optionsArray.forEach (optionItem => {
        var option = document.createElement("option");
        option.className = "font-weight-bolder";
        option.value = optionItem["value"] || optionItem["name"]; // default to name if value is not provided
        option.innerText = optionItem["name"];
        option.selected = optionItem["isSelected"];
        option.disabled = optionItem["isDisabled"]; // add this line to set the disabled attribute
        option.hidden = optionItem["isHidden"];
        option.dataset.id = optionItem["id"]; // retrieve using element.dataset.id
        // Set the color attribute
        option.dataset.colour = optionItem["colour"]; // Add colour attribute to option

        div.appendChild(option);
    });
}

export { createSelectOptions }