function assembleBoltDataset(data) {
    if (data.inspection_data.length === 0) {
        return null;
    }
    var chartData = data["inspection_data"];
    var thresholds = data["thresholds"];
    var labels = chartData[0].labels;
    var dataSets = [];
    var inspectionDropdown = [];
    var overview = data["overview"];
    var damagedBolts = []; // Array to store bolt numbers of damaged bolts

    for (let inspection of chartData) {
        var dataset = {
            label: inspection.inspection_label,
            id: inspection.inspection_id,
            averageLoad: inspection.inspection_average_load,
            backgroundColor: inspection.inspection_color,
            hoverBackgroundColor: lightenColor(inspection.inspection_color, 20),
            data: inspection.loads,
            bolt_ids: inspection.bolt_ids
        };
        var dropDowndata = {
            name: inspection.inspection_label,
            id: inspection.inspection_id
        };
        dataSets.push(dataset);
        inspectionDropdown.push(dropDowndata);

        // Check for damaged_values and correlate with bolt_numbers
        for (let i = 0; i < inspection.damaged_values.length; i++) {
            if (inspection.damaged_values[i]) {
                damagedBolts.push(inspection.labels[i]); // Assuming bolt_ids holds the bolt numbers
            }
        }
    }

    // Add the damaged bolt numbers to the overview hash
    overview.damaged_bolts = damagedBolts;

    return {
        overview: overview,
        thresholds: thresholds,
        inspections: inspectionDropdown,
        data: {
            labels: labels,
            datasets: dataSets
        }
    };
}


function lightenColor(color, percentage) {
    // Convert the color to RGB format
    var rgb = colorToRGB(color);

    // Calculate the new lightened color
    var newColor = {
        r: Math.round(rgb.r + ((255 - rgb.r) * (percentage / 100))),
        g: Math.round(rgb.g + ((255 - rgb.g) * (percentage / 100))),
        b: Math.round(rgb.b + ((255 - rgb.b) * (percentage / 100)))
    };

    // Convert the new color back to hexadecimal format
    return rgbToHex(newColor.r, newColor.g, newColor.b);
}

function colorToRGB(color) {
    var hex = color.replace('#', '');

    return {
        r: parseInt(hex.substring(0, 2), 16),
        g: parseInt(hex.substring(2, 4), 16),
        b: parseInt(hex.substring(4, 6), 16)
    };
}

function rgbToHex(r, g, b) {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
}

export { assembleBoltDataset }