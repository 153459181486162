import {addAjaxPromise} from "../shared/ajax_promise"

function fetchMapSiteData(siteId) {
    const promise = new Promise((resolve, reject) => {

        // Get chart data for each inspection
        $.ajax({
            type: "POST",
            url: "/api/site_map_data",
            data: JSON.stringify({ site: siteId }),
            cache: false,
            contentType: "application/json",
            success: function (data) {
                resolve(data);
            },
            error: function (err) {
                resolve({ error: err });
            }
        });
    });

    addAjaxPromise(promise);

    return promise;

};

export { fetchMapSiteData };