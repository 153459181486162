function createElementWithClass(element, className = "") {
    var ele = document.createElement(element);
    if (className) {
        var classList = className.split(" ");
        classList.forEach(function (value, index) {
            ele.classList.add(value);
        });
    }
    return ele;
}

export { createElementWithClass };