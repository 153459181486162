import './_page_handler'
import './shared/map_event_listeners'
import './event_listeners/filter_selector_event_listeners'
import './shared/sort_by_selector_event_listeners'
import './event_listeners/tab_event_listeners'
import './event_listeners/misc_event_listeners'
import  {state, updateState} from "./shared/state";
import {router} from './shared/router'
import './shared/state_change_event_listeners'
import { Sidebar, SidebarCard, SidebarCardBody, SidebarTabDiv, SidebarTabContentDiv, NavTabs, TabPanes, SidebarLabel } from './components/sidebar_components'
import {createFleetSiteSelectors} from "./fleet/create_fleet_site_selectors";
import {createTurbineConnectionStatusSelectors} from "./site/create_turbine_connection_status_selectors";
import {SecondarySelectors} from "./components/secondary_selector_components";
import {siteTabContent} from "./site/site_tab_content";
import {SiteTableCard} from "./components/fleet_overview_components";
import {Spinner} from "./components/spinner_component";
import {Modal} from "./components/nav_components"
import {Key} from "./components/key_component"
// Create SPA consistent elements


// Initialize your application state;
$(document).ready(function () {
    const mainContentRow = document.getElementById('main-content-row')
    mainContentRow.appendChild(Sidebar().render());
    mainContentRow.appendChild(Spinner().render());
    mainContentRow.appendChild(Key().render());
    document.getElementById('boltwatch-sidebar').appendChild(SidebarCard().render());
    document.getElementById('sidebar-card').appendChild(SidebarCardBody().render());
    document.getElementById('sidebar-card-body').appendChild(SidebarTabDiv().render());
    document.getElementById('sidebar-card-body').appendChild(SidebarTabContentDiv().render());
    document.getElementById('sidebar-tab-div').appendChild(NavTabs().render());
    document.getElementById('tab-content-div').appendChild(SecondarySelectors().render());
    document.getElementById('tab-content-div').appendChild(TabPanes().render());
    const fleetTab = document.getElementById("fleet-tab")
    document.getElementById('myTab').insertBefore(SidebarLabel("OVERVIEW").render(), fleetTab)
    const relaxationTab = document.getElementById("relaxation-tab")
    document.getElementById('myTab').insertBefore(SidebarLabel("CHARTS").render(), relaxationTab)
    document.body.appendChild(Modal().render());


    var fleetName = window.APP_DATA.fleetName
    var fleets = window.APP_DATA.fleets
    createFleetSiteSelectors(fleetName, fleets); // TODO: enable multifleet
    createTurbineConnectionStatusSelectors();

    // Add SumoSelect to the inspection dropdowm
    $('.SlectBox').SumoSelect();

    $('#turbine-selector').SumoSelect({
        //okCancelInMulti: true,
        selectAll: true,
        placeholder: "All Turbines",
        captionFormatAllSelected: "All Turbines",
        csvDispCount: 3,
        captionFormat: '{0} Turbines Selected'
    });

    $('#status-selector').SumoSelect()
    $('#connection-selector').SumoSelect()


    // These are the basic structures shared by all
    siteTabContent(); // TODO: refactor as "react-like component"
    document.getElementById('v-pills-fleet').appendChild(SiteTableCard().render());


    router.notFound(function () {
        router.navigate('/error');
    });

    if (window.APP_DATA.unauthorized === 'true') {
        router.navigate(`/fleets/${window.APP_DATA.fleetId}`)
    }
    // Start the router
    router.resolve();

    // Enable navigation for anchor tags with "data-navigo" attribute
    router.updatePageLinks();
});
