function CommentComponent(comment) {
    function render() {
        // Create the main timeline item
        const timelineItem = document.createElement('li');
        timelineItem.className = 'timeline-item mb-5';

        // Create the icon container
        const timelineIcon = document.createElement('span');
        timelineIcon.className = 'timeline-icon';

        // Create the icon
        const icon = document.createElement('i');
        icon.className = 'material-icons';
        icon.innerText = 'description';

        timelineIcon.appendChild(icon);
        timelineItem.appendChild(timelineIcon);

        // Create the title
        const commentTitle = document.createElement('h5');
        commentTitle.className = 'fw-bold';
        commentTitle.innerHTML = `${comment.comment.commentable_type} ${comment.identifier}`;

        timelineItem.appendChild(commentTitle);

        // Create the date and file container
        const dateFileContainer = document.createElement('div');
        dateFileContainer.className = 'd-flex align-items-center'; // Removed justify-content-between

// Create the date
        const commentDate = document.createElement('p');
        commentDate.className = 'comment-date text-muted mb-2 fw-bold';
        commentDate.innerHTML = new Date(comment.comment.created_at).toLocaleDateString();
        dateFileContainer.appendChild(commentDate);

// Check if there are any files to link
        if (comment.files.length > 0) {
            comment.files.forEach(file => {
                const fileLink = document.createElement('a');
                fileLink.href = file.url;
                fileLink.target = "_blank";
                fileLink.className = 'comment-file text-muted ml-2'; // Added margin-left for spacing

                // Create paperclip icon
                const paperclipIcon = document.createElement('i');
                paperclipIcon.className = 'material-icons';
                paperclipIcon.textContent = 'attach_file';
                fileLink.appendChild(paperclipIcon);

                // Add space or separator after the icon
                const textSpace = document.createTextNode(' ');
                fileLink.appendChild(textSpace);

                // Add filename
                const fileNameText = document.createTextNode(file.filename);
                fileLink.appendChild(fileNameText);

                dateFileContainer.appendChild(fileLink);
            });
        }

        timelineItem.appendChild(dateFileContainer);


        // Create the comment text
        const commentText = document.createElement('p');
        commentText.className = 'comment-text';
        commentText.innerText = comment.comment.text;

        timelineItem.appendChild(commentText);

        return timelineItem;
    }

    return {
        render: render
    };
}

function CommentHeader(level) {
    function render() {
        const headerdiv = document.createElement("div")
        headerdiv.className = "h2 comment-header"
        headerdiv.innerText = capitalizeFirstLetter(level) + " level comments"
        headerdiv.id = "comment-header-div"

        return headerdiv
    }

    return {
        render: render
    }
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export { CommentComponent, CommentHeader }