function assembleSelectorData(asset, ajaxData) {
  if (asset === "fleet") {
    var optionsArray = [{ name: (ajaxData["fleet"]), isSelected: true}]
  } else if (asset === "site") {
    var optionsArray = [{name: "All Sites", id: null, isSelected: true}]
    ajaxData[asset + "s"].forEach(optionItem => {
      var optionName = (optionItem["name"])
      var optionID = (optionItem["id"])
      var optionHash = {name: optionName, id: optionID, isSelected: false}
      optionsArray.push(optionHash)
    })
  } else if (asset === "turbine") {

    var allTurbinesIds = ajaxData[asset + "s"].map(obj => parseInt(obj.id)).join(',');
    var optionsArray = []
    //var optionsArray = [{name: "All Turbines", id: allTurbinesIds, isSelected: false, isHidden: true}]
    ajaxData[asset + "s"].forEach(optionItem => {
      var optionName = (optionItem["name"])
      var optionID = (optionItem["id"])
      var optionColour = (optionItem["color"])
      var optionHash = {name: optionName, id: optionID, isSelected: false, colour: optionColour}
      optionsArray.push(optionHash)
    })
    document.querySelector('#turbine-selector').setAttribute('data-all-turbines', allTurbinesIds);

  } else if (asset === "connection") {
    var allConnections = ajaxData[asset + "s"].map(obj => obj.connection_type)
    var optionsArray = [{name: "-- select a connection --", isSelected: false, isDisabled: true, value: "placeholder"},
                        {name: "All Connections", id: allConnections, isSelected: true}]
    ajaxData[asset + "s"].forEach(optionItem => {
      var optionName = (optionItem["connection_type"])
      var optionID = (optionItem["connection_type"])
      var optionHash = {name: optionName, id: optionID, isSelected: false}
      optionsArray.push(optionHash)
    })
  } else if (asset === "inspection") {
    var optionsArray = [{name: "-- select an inspection --", isSelected: false, isDisabled: true, value: "placeholder"}]//,
    if (ajaxData !== "default") {

      ajaxData[asset + "s"].forEach(optionItem => {
        var optionName = (optionItem["name"])
        var optionID = (optionItem["id"])
        var optionHash = {name: optionName, id: optionID, isSelected: false}
        optionsArray.push(optionHash)
      })
    }
  } else {
    var optionsArray = [{name: "", id: null, isSelected: false}]
  }

  return optionsArray
}

export { assembleSelectorData }