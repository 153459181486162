import {renderRelaxationChart} from "../render/render_relaxation_chart";
import {renderBoltChart} from "../render/render_bolt_chart";
import {state, updateState} from "./state";
import {renderTimeline} from "../render/render_timeline";
import {renderSiteOverview} from "../render/render_site_overview";
import {renderFleetOverview} from "../render/render_fleet_overview";
import {renderMap} from "../render/render_map";
import {renderSelectors} from "../render/render_selectors";
import {activateTab} from "./activate_tab";
import {getAllAjaxPromises, turnOnLoader, handleAjaxErrors, turnOffLoader} from "./ajax_promise";
import {renderSiteOverviewFilter} from "../render/render_site_overview_filter";

function render(stateChangeSummary) {
    // Show the spinner overlay
    turnOnLoader();
    renderMap(stateChangeSummary);
    renderFleetOverview(stateChangeSummary);
    renderSiteOverview(stateChangeSummary);
    renderTimeline(stateChangeSummary)

    // TODO: finish refactoring the below:
    //renderRelaxationChart(state.turbine, state.connection);
    //renderBoltChart(state.turbine, state.connection);
    // which tab to active? pass in stateChangeSummary???

    if (stateChangeSummary.includes("turbine") || stateChangeSummary.includes("connection")) {
        if (state.connection != "All Connections" && state.connection != null && state.turbine.length === 1) {
            // if one connection on one turbine
            renderRelaxationChart(state.turbine, state.connection);
            renderBoltChart(state.turbine, state.connection);
            //renderSelectors();
            //activateTab("site") // was bolt
        } else if (state.connection != "All Connections" && state.connection != null && state.turbine.length != 1) {
            renderRelaxationChart(state.turbine, state.connection);
            renderBoltChart(state.turbine, state.connection);
            //renderSelectors();
            //activateTab("site") // was bolt
        } else if (state.site != null && (state.connection === "All Connections" || state.connection === null)){
            // more than one connection is selected
            renderRelaxationChart(state.turbine, state.connection);
            renderBoltChart(state.turbine, state.connection);
            activateTab("site") // these might need to move
        } else if (state.site === null) {
            renderRelaxationChart(state.turbine, state.connection);
            renderBoltChart(state.turbine, state.connection);
            //updateState({status: "All Status Levels"})
            activateTab("fleet")
        }
    } else if (stateChangeSummary.includes("fleet") && stateChangeSummary.includes("site")) {
        // TODO: change this to handle direct navigation to site route
        renderRelaxationChart(state.turbine, state.connection);
        renderBoltChart(state.turbine, state.connection);
        //renderTimeline(state.site);
        if (state.site != null) {
            activateTab("site")
        } else {
            //updateState({status: "All Status Levels"})
            activateTab("fleet")
        }
    } else if (stateChangeSummary.includes("fleet") || state.site === null) {
        //renderMap();
        //renderFleetOverview(state.fleet);
        //renderSiteOverview(state.site);
        renderRelaxationChart(state.turbine, state.connection);
        renderBoltChart(state.turbine, state.connection);
        //updateState({status: "All Status Levels"})
        //renderTimeline(state.site);
        activateTab("fleet")
    } else if (stateChangeSummary.includes("site")) {
        //renderMap();
        //renderSiteOverview(state.site);
        activateTab("site")
        renderRelaxationChart(state.turbine, state.connection);
        renderBoltChart(state.turbine, state.connection);
        //renderTimeline(state.site);
        activateTab("site")
    }
    // Hide the spinner overlay
    Promise.all(getAllAjaxPromises()).then((results) => {
        renderSelectors(stateChangeSummary);
        renderSiteOverviewFilter(stateChangeSummary);
        adjustDisplay();
        turnOffLoader();
    }).catch(handleAjaxErrors);
}

function adjustDisplay() {
    if ($('#v-pills-fleet').hasClass('active')) {
        document.getElementById("secondary-selectors").style.display = 'none';
    } else {
        document.getElementById("secondary-selectors").style.display = 'flex';
    }
}


export {render};