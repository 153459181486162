const ajaxPromises = [];

function addAjaxPromise(promise) {
    ajaxPromises.push(promise);
}

function getAllAjaxPromises() {
    return ajaxPromises;
}

function handleAjaxErrors(error) {
    // Handle AJAX errors here
    console.log('An AJAX error occurred:', error);
}

function turnOnLoader() {
    document.getElementById("spinner-overlay").style.display = "flex";
}


function turnOffLoader() {
    document.getElementById("spinner-overlay").style.display = "none";
}

export {addAjaxPromise, getAllAjaxPromises, handleAjaxErrors, turnOffLoader, turnOnLoader}