function Spinner() {
    // Render method of the component
    function render() {
        const spinnerOverlay = document.createElement("div");
        spinnerOverlay.id = "spinner-overlay";

        const spinner = document.createElement("div");
        spinner.className = "spinner d-flex align-items-center justify-content-center";

        const spinnerBorder = document.createElement("div");
        spinnerBorder.className = "spinner-border text-primary";
        spinnerBorder.role = "status";

        const spinnerText = document.createElement("span");
        spinnerText.className = "sr-only";
        spinnerText.textContent = "Loading...";

        spinnerBorder.appendChild(spinnerText);
        spinner.appendChild(spinnerBorder);
        spinnerOverlay.appendChild(spinner);

        return spinnerOverlay;
    }

    return {
        render,
    };
}

export {Spinner}

