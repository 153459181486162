import {state, updateState} from "../shared/state"
import {router} from "../shared/router";

function filterColumnsBySeverityRange() {
    var dataTable = $('#connectionStatusTable').DataTable();
    var status = state.status;
    var summary = false //status === 'Turbine Summary';
    var statusArray = getStatusArray(status); // Define this function based on your previous logic

    $('#connectionStatusTable').hide();
    // Reset and apply initial filters
    dataTable.columns().visible(true);
    dataTable.column('connection:name').search('^((?!Summary).)*$', true, false).draw(); // hides the summary row
    
    //dataTable.search('').columns().search('').column('connection:name').draw();

    var selectedTurbineColumns = getSelectedTurbineColumns(); // Returns the names of the turbines selected
    var visibleColumns = [0]; // Include the first column (header column)
    var filteredTurbineIds = []

    dataTable.columns().every(function(index) {
        if (index === 0) return; //|| (selectedTurbineColumns !== "All Turbines" && !selectedTurbineColumns.includes(this.header().innerHTML))) return;
        if (status === "All Status Levels" || status === null) {
            if (selectedTurbineColumns != "All Turbines" && !selectedTurbineColumns.includes(this.header().innerHTML)) return;
        }

        var columnHasSeverityInRange = false;

        for (var i = 0; i < dataTable.rows().data().length; i++) {
            var row = dataTable.rows().data()[i];
            var connectionFilter = summary ? row.connection === "Summary" : row.connection !== "Summary";
            if (!connectionFilter) continue;

            var columnData = row[this.header().innerHTML];
            if (columnData && statusArray.includes(columnData.status)) {
                columnHasSeverityInRange = true;
                break;
            }
        }

        if (columnHasSeverityInRange) {
            visibleColumns.push(index);
            filteredTurbineIds.push(columnData.turbine_id)
        }
    });


    dataTable.columns().visible(false);
    dataTable.columns(visibleColumns).visible(true);
    $('#connectionStatusTable').show();

    var currentTurbines = state.turbine.sort().join(", ")
    // Your row filtering logic here...
    filterRowsByConditions();

    if (filteredTurbineIds && (filteredTurbineIds.sort().join(", ") != currentTurbines)) {
        // render the turbine view...
        var encodedTurbineIds = encodeURIComponent(filteredTurbineIds)
        if (state.connection === null) {
            var connection = encodeURIComponent("All Connections")
        } else {
            var connection = encodeURIComponent(state.connection)
        }
        updateState({statusSelectedTurbines: filteredTurbineIds})
        router.navigate(`fleets/${state.fleet}/sites/${state.site}/connection_type/${connection}/turbines/${encodedTurbineIds}`);
    }
}

function getSelectedTurbineColumns() {
    var selectedTurbineColumns;

    if ($("#turbine-selector option:selected").length > 0) {
        selectedTurbineColumns = $("#turbine-selector option:selected").map(function() {
            return $(this).text(); // Retrieve the text (name) of the option
        }).get();
    } else {
        selectedTurbineColumns = "All Turbines"
    }
    return selectedTurbineColumns;
}

function getStatusArray(status) {
    var statusArray;

    switch (status) {
        case 'Red':
            statusArray = ["danger"];
            break;
        case 'Amber':
            statusArray = ["warning"];
            break;
        case 'Red or Amber':
            statusArray = ["danger", "warning"];
            break;
        case 'Green':
            statusArray = ["success"];
            break;
        case 'All Status Levels':
            statusArray = ["success", "warning", "danger", "primary"];
            break;
        default:
            statusArray = ["success", "warning", "danger", "primary"];
            break;
    }

    return statusArray;
}

function filterRowsByConditions() {
    // Fetch the DataTable instance
    var dataTable = $('#connectionStatusTable').DataTable();

    // Reset search to include all rows initially
    dataTable.search('').draw();

    // Extract necessary variables from the state
    var status = state.status;
    var connection = state.connection;

    // Check the status and connection to apply row filters
    if (connection === "All Connections") {
        // Show all rows except the 'Summary' row
        dataTable.column('connection:name').search('^((?!Summary).)*$', true, false).draw();
    } else if (connection === null) {
        return;
    } else {
        // Show only the row with connection name that matches the state.connection
        dataTable.column('connection:name').search(connection).draw();
    }
}

export { filterColumnsBySeverityRange };
