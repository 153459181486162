import {fetchTimelineData} from "../ajax/fetch_timeline_data";
import {CommentComponent, CommentHeader} from "../components/timeline_components";
import {clearTabContents} from "../site/clear_tab_contents";
import {handleAjaxErrors} from "../shared/ajax_promise";
import {state} from "../shared/state"

function renderTimeline(stateChangeSummary) {
    // empty the timeline div
    var timelineMainDiv = document.getElementById('v-pills-comments')
    var commentHeader = document.getElementById('comment-header-div')

    if (stateChangeSummary.includes("connection") || stateChangeSummary.includes("turbine") || stateChangeSummary.includes("site") ) {
        if (state.connection != "All Connections" && state.connection != null && state.turbine.length === 1) {
            // if one connection on one turbine
            clearTabContents(timelineMainDiv);
            dataFetch("connection", state)
        } else if (commentHeader && commentHeader.innerText.includes("Connection") && !(state.connection != "All Connections" && state.connection != null && state.turbine.length === 1)) {
            // if it was connection and now it is not
            clearTabContents(timelineMainDiv);
            dataFetch("site", state)
        } else if (stateChangeSummary.includes("site")) {
            if (state.site === null) {
                clearTabContents(timelineMainDiv);
                return
            } else {
                clearTabContents(timelineMainDiv);
                dataFetch("site", state)
            }
        } else {
            return
        }
    }

}

function dataFetch(asset, ids) {
    var timelineMainDiv = document.getElementById('v-pills-comments')
    var timelineDiv = document.createElement("ul")
    timelineDiv.className = "timeline-with-icons"
    timelineMainDiv.appendChild(timelineDiv)

    fetchTimelineData(asset, ids).then((ajaxData) => {

        const header = CommentHeader(asset).render();
        timelineDiv.appendChild(header)

        const commentsData = ajaxData
        const commentsComponents = commentsData.map(commentData => CommentComponent(commentData));

        commentsComponents.forEach(commentComponent => {
            const renderedComment = commentComponent.render();
            timelineDiv.appendChild(renderedComment);
        });
    }).catch(handleAjaxErrors)
}

export { renderTimeline }