import {setSelectorSelected} from "../../shared/set_selector_selected";
import {state} from "../../shared/state";

function renderConnectionSelector(stateChangeSummary) {
    if (stateChangeSummary.includes('connection')) {
        if (state.connection === null) {
            setSelectorSelected('connection-selector', 'placeholder');
            $('#connection-selector')[0].sumo.reload();
        } else {
            setSelectorSelected('connection-selector', state.connection)
            $('#connection-selector')[0].sumo.reload();
        }
    }
}

export {renderConnectionSelector}