import {addAjaxPromise} from "../shared/ajax_promise";

function fetchFleetTableData(fleetId) {
    const promise = new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: "/api/sites_list",
            data: JSON.stringify({ fleet: fleetId }),
            cache: false,
            contentType: "application/json",
            success: function (responseData) {
                const { fleet, siteIds } = responseData;
                const sitePromises = siteIds.map(siteId => fetchSitesData(siteId));
                Promise.all(sitePromises)
                    .then(siteDataList => {
                        const fleetData = { "fleet": fleet, "sites": siteDataList};
                        resolve(fleetData);
                    })
                    .catch(error => {
                        resolve({ error: error });
                    });
            },
            error: function (err) {
                resolve({ error: err });
            }
        });
    });

    addAjaxPromise(promise); // Add the AJAX promise to the array

    return promise;
}

function fetchSitesData(siteId) {
    const promise = new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: "/api/sites_data",
            data: JSON.stringify({ site: siteId }),
            cache: false,
            contentType: "application/json",
            success: function (data) {
                resolve(data);
            },
            error: function (err) {
                resolve({ error: err });
            }
        });
    });
    addAjaxPromise(promise);
    return promise;
}

export { fetchFleetTableData };
