function SiteTableCard() {
    function render() {
        const siteTableCard = document.createElement("div");
        siteTableCard.id = "site-table-card";
        siteTableCard.className = "card site-table-card border-0";

        let sortByDiv = SortByDiv();
        siteTableCard.appendChild(sortByDiv.render());
        let siteTableCanvas = SiteTableCanvas();
        siteTableCard.appendChild(siteTableCanvas.render());

        return siteTableCard;
    }

    return {
        render,
    };
}

function SiteTableCanvas() {
    function render() {
        const siteTableCanvas = document.createElement("div");
        siteTableCanvas.className = "mr-0 hover w-100";
        siteTableCanvas.id = "site-table-canvas";

        return siteTableCanvas;
    }

    return {
        render,
    };
}

function SortByDiv() {
    function render() {
        const sortbyDiv = document.createElement("div");
        sortbyDiv.className = "d-flex align-items-center";
        const sortbyIcon = document.createElement("i");
        sortbyIcon.className = "bi bi-sort-down pb-1 pl-0 pr-2 pt-2 ml-5";
        const sortbyLabel = document.createElement("p");
        sortbyLabel.className = "p-2 m-0";
        sortbyLabel.innerText = "Sort by: ";
        const sortbySelect = document.createElement("select");
        sortbySelect.className = "sortby-dropdown border-0 text-center font-weight-bolder";
        sortbySelect.id = "sortby-dropdown";

        const option1 = document.createElement("option");
        option1.className = "font-weight-bolder";
        option1.value = "1";
        option1.innerText = "Site Name";
        sortbySelect.appendChild(option1);

        const option2 = document.createElement("option");
        option2.className = "font-weight-bolder";
        option2.value = "4";
        option2.innerText = "Last Inspected";
        sortbySelect.appendChild(option2);

        const option3 = document.createElement("option");
        option3.className = "font-weight-bolder";
        option3.value = "6";
        option3.selected = true;
        option3.innerText = "Connection Status";
        sortbySelect.appendChild(option3);

        sortbyDiv.appendChild(sortbyIcon);
        sortbyDiv.appendChild(sortbyLabel);
        sortbyDiv.appendChild(sortbySelect);

        return sortbyDiv;
    }

    return {
        render,
    };
}

export { SiteTableCard }