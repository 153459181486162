import { state, updateState } from "../shared/state";
import { router } from "../shared/router";

$(document).ready(function () {
    // Event delegation for fleet-selector
    $(document).on('change', '#fleet-selector', function () {
        var selectedOption = $(this).find('option:selected');
        var fleetId = selectedOption.data('id');

        router.navigate(`fleets/${fleetId}`);
    });

    // Event delegation for site-selector
    $(document).on('change', '#site-selector', function () {
        var selectedSite = $(this).val();
        var selectedOption = $(this).find('option:selected');
        var siteId = selectedOption.data('id');
        var fleetId = state.fleet;


        if (selectedSite == "All Sites") {
            router.navigate(`/fleets/${fleetId}`);
        } else {
            router.navigate(`fleets/${fleetId}/sites/${siteId}`);
        }
    });

    // Event delegation for filter-connection-dropdown
    $(document).on('sumo:closed', '#connection-selector', function () {
        var selectedConnection = $(this).val();
        var connectionType = encodeURIComponent(selectedConnection)
        if (state.turbine.length === 0) {
            var turbines = encodeURIComponent("All Turbines")
        } else {
            var turbines = encodeURIComponent(state.turbine)
        }

        if (state.status === "Turbine Summary") {
          updateState({status: "All Inspected Turbines"});
        }

        router.navigate(`fleets/${state.fleet}/sites/${state.site}/connection_type/${connectionType}/turbines/${turbines}`);
    });

    // Turbine multiselect event delegation
    $(document).on('sumo:closed', '#turbine-selector', function() {
        var turbineIds;

        var selectedOptionsCount = $("#turbine-selector option:selected").length;

        var allTurbineIds = $('#turbine-selector').data('all-turbines');

        if (selectedOptionsCount > 0) {
            turbineIds = $("#turbine-selector option:selected").map(function() {
                return $(this).data('id');
            }).get();
            if (turbineIds.join(',') === allTurbineIds) {
                turbineIds = "All Turbines";
            }
        } else {
            var sumoSelect = $(this)[0].sumo;
            sumoSelect.selectAll();
            turbineIds = "All Turbines";
        }

        var encodedTurbineIds = encodeURIComponent(turbineIds)

        var connectionType = (state.connection === null)
            ? encodeURIComponent("All Connections")
            : state.connection;

        router.navigate(`fleets/${state.fleet}/sites/${state.site}/connection_type/${connectionType}/turbines/${encodedTurbineIds}`);
    });


    // status dropdown event delegation
    $(document).on('sumo:closed', '#status-selector', function() {
        var selectedRange = $(this).val();

        if (selectedRange === "All Status Levels") {
            var turbines = encodeURIComponent("All Turbines")
            var connection = encodeURIComponent(state.connection)
            router.navigate(`fleets/${state.fleet}/sites/${state.site}/connection_type/${connection}/turbines/${turbines}`);
        } else {
            updateState({status: selectedRange});
        }
    });

    // Event delegation for inspection selector
    $(document).on('change', '#inspection-selector', function () {
        var table = $('#connectionStatusTable').DataTable();
        var selectedRange = $(this).val();

        // New state
        updateState({inspection: selectedRange});
    });
});




