import {addAjaxPromise} from "../shared/ajax_promise";

function fetchMapFleetData(id) {
    const promise = new Promise((resolve, reject) => {
        // Get fleet
        var fleetId = id

        // Get chart data for each inspection
        $.ajax({
            type: "POST",
            url: "/api/fleet_map_data",
            data: JSON.stringify({ fleet: fleetId }),
            cache: false,
            contentType: "application/json",
            success: function (data) {
                resolve(data);
            },
            error: function (err) {
                resolve({ error: err });
            }
        });
    });

    addAjaxPromise(promise); // Add the AJAX promise to the array

    return promise;
}


// Export the function for use in other files
export { fetchMapFleetData };