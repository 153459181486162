// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");

window.$ = $;
window.jQuery = $;
window.dateformat = require("dateformat");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);
const fonts = require.context('../fonts', true);
const fontPath = (name) => fonts(name, true);

import "bootstrap";
import "stylesheets/application";

import 'datatables.net'
import 'datatables.net-select'

import 'datatables.net-scroller-dt';
import 'datatables.net-fixedcolumns-bs4';
import 'datatables.net-bs4'
import 'datatables.net-select-bs4'
import 'datatables.net-colreorder-bs4';

import 'chartjs-plugin-annotation'

import 'select2';
import 'select2/dist/css/select2.css';

import '../views/shared/syncro' // TODO: move this

import '../views/dashboard'