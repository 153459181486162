import {downloadInspectionCSV} from "../ajax/download_inspection_csv";

$(document).ready(function () {

    // Download button
    $(document).on('click', '.inspection-download-button', function () {
        var inspectionId = this.getAttribute('data-inspection-id');
        downloadInspectionCSV(inspectionId)
    });

    // Nav Help Icon
        const helpIcon = document.getElementById("help");

        // Add a click event listener to the help icon
        if (helpIcon) {
            helpIcon.addEventListener("click", function() {
                const modalElem = document.getElementById("helpModal");
                if (modalElem) {
                    // Use Bootstrap's modal functionality to show the modal
                    $(modalElem).modal('show');  // Assuming you're using jQuery with Bootstrap
                }
            });
        }

    // Hide secondary selectors on fleet tab
});
