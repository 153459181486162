function clearTabContents(tabDiv) {

    if (!tabDiv) { return }

    let children = tabDiv.childNodes;

    for (let i = children.length - 1; i >= 0; i--) {
        let child = children[i];
            tabDiv.removeChild(child);
    }
}


export { clearTabContents }
