import {fetchSiteOverviewTableData} from "../ajax/fetch_site_overview_table_data";
import {repopulateSelectorOptions} from "../shared/_repopulate_selector_options";
import {clearElements} from "../_clear_elements";
import {state} from "../shared/state";
import {activateTab} from "../shared/activate_tab";
import {handleAjaxErrors} from "../shared/ajax_promise";
import {router} from "../shared/router";
import {initialTableFilter} from "../shared/inital_table_filter";
import {setSelectorSelected} from "../shared/set_selector_selected";

function renderSiteOverview(stateChangeSummary) {
    if (stateChangeSummary.includes("site") || stateChangeSummary.includes("connection")) {
        var siteId = state.site

        clearElements('connection-status-table-canvas');

        // Remove the existing table
        if ($('#connectionStatusTable').length != 0) {
            if ($.fn.DataTable.isDataTable('#connectionStatusTable')) {
                var tableToDestroy = $('#connectionStatusTable').DataTable();
                tableToDestroy.destroy();
            }
        }

        if (siteId === null) {
            return;
        }

        let selectedConnectionTypes = state.connection;

        fetchSiteOverviewTableData(siteId, selectedConnectionTypes).then((ajaxData) => {
            repopulateSelectorOptions("turbine", ajaxData)
            repopulateSelectorOptions("connection", ajaxData)
            setSelectorSelected("site-selector", ajaxData["site"]);
            createSiteOverview(ajaxData["site_overview"])
        }).catch(handleAjaxErrors)
        activateTab("site"); // move it to here to see if that helps
    }
}

function createSiteOverview(chartData) {
    // Create a new table
    const startTime = Date.now();
    var table = $('<table id="connectionStatusTable" class="site-table mr-0 hover w-100">');
    $('#connection-status-table-canvas').append(table);
    $('#connectionStatusTable').hide();

    var tableData = chartData;
    // Get the keys of the first object in the data array
    var columnKeys = Object.keys(tableData[0]);
// Create the column definitions dynamically based on the keys
    var columns = columnKeys.map(function (key, index) {
        // Skip the first columnKey
        if (index === 0) return null;

        return {
            title: key,
            data: key,
            orderable: false,
            width: '10%',
            render: function (data, type, row) {
                var div = document.createElement('div');
                div.setAttribute('class', 'sites-table-cell boltwatch-alert-' + data.status);
                div.setAttribute('data-severity', data.severity);
                div.setAttribute('data-connection-type', data.connection_type);
                div.setAttribute('data-turbine-id', data.turbine_id);
                div.setAttribute('data-status', data.status);

                // Check if data.damaged is true and then append the warning icon
                if (data.damaged) {
                    div.innerHTML = '<span class="material-icons">warning</span>';
                }
                return div.outerHTML;
            }
        };
    }).filter(function (column) {
        return column !== null;
    });

    // Add the first column definition with the necessary options
    columns.unshift({
        data: 'connection',
        name: 'connection',
        width: '150px',
        orderable: false,
        visible: true // Set the first column to be always visible
    });

    // Initialize DataTable
    var dataTable = $('#connectionStatusTable').DataTable({
        responsive: false,
        paging: false,
        info: false,
        searching: true,
        filter: true,
        data: tableData,
        //scrollY: '400px',
        scrollX: true,
        scrollCollapse: true,
        fixedColumns: true,
        colReorder: {
            enable: true,
            fixedColumnsLeft: 1
        },
        order: [[0, 'asc']],
        columns: columns
    })

    // Set the table data using the data() method
    //dataTable.clear().rows.add(tableData).draw();

    // Set the inital view to be the summary row only, in order of severity
    //dataTable.columns().visible(true);
    //dataTable.column('connection:name').search('Summary').draw()
    //var filteredData = dataTable.rows({ search: 'applied' }).data()[0];
    //initialTableFilter()
    //reorderTurbineColumns(dataTable.rows({search: 'applied'}).data()[0]);
    //filterColumnsBySeverityRange()
    //var filteredData = dataTable.rows({search: 'applied'}).data();
    //reorderTurbineColumns(filteredData[0]);
    // Attach event listener to reorder the columns
    //dataTable.on('draw', function () {
    //    var filteredData = dataTable.rows({search: 'applied'}).data();
    //    reorderTurbineColumns(filteredData[0]);
    //});
    //if (state.connection === "All Connections" || state.connection === null) {
    //    $('#connectionStatusTable').show(); // single connection not filtered yet
    //}

    // Event listener for cell link click
    $('#connectionStatusTable').on('click', 'td', function () {
        if (this._DT_CellIndex.column === 0) {
            return;
        }
        var cellData = dataTable.cell(this).data();
        
        var connectionType = cellData.connection_type;
        var turbineId = cellData.turbine_id;

        router.navigate(`fleets/${state.fleet}/sites/${state.site}/connection_type/${connectionType}/turbines/${turbineId}`);
        activateTab("bolt");
    });
    const endTime = Date.now();
    const timeTaken = endTime - startTime;
    console.log(`createSiteOverview time: ${timeTaken} ms`);
}

export { renderSiteOverview }