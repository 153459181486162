import {state} from "../../shared/state";
import {setSelectorSelected, setSelectorSelectedById} from "../../shared/set_selector_selected";

function renderSiteSelector(stateChangeSummary) {
    
    if (stateChangeSummary.includes("fleet") && stateChangeSummary.includes("site") && state.site != null) {
        // setSelectorSelectedById('site-selector', state.site)
    } else if (stateChangeSummary.includes("site")) {
        if (state.site === null) {
            setSelectorSelected('site-selector', "All Sites")
        }
    };

}

export {renderSiteSelector}