function Key() {
    function render() {
        const keyDiv = document.createElement("div");
        keyDiv.id = "boltwatch-key";
        keyDiv.className = "d-flex flex-column boltwatch-key pr-0 position-absolute";

        const entries = [
            { text: 'Action Needed This Year', color: '#E54B4B' },
            { text: 'Reinspect Next Year', color: '#FCBF49' },
            { text: 'Good Status', color: '#45CE63' },
            { text: 'Limited Data', color: '#22ADF6' },
            //{ text: 'Not Inspected', color: '#F7F7F9' },
            { text: 'Damage', icon: true } // Added this entry for Damaged Bolt
        ];

        entries.forEach(entry => {
            const innerDiv = document.createElement("div");
            innerDiv.className = "key-div d-flex align-items-center justify-content-between";

            const textElement = document.createElement("span");
            textElement.className = "key-text"
            textElement.innerHTML = entry.text;
            textElement.style.width = '180px';  // Set a fixed width. Adjust this value based on your needs.
            innerDiv.appendChild(textElement);

            if (entry.icon) {
                const iconElement = document.createElement("span");
                iconElement.className = "material-icons";
                iconElement.textContent = "warning";
                innerDiv.appendChild(iconElement);
            } else {
                const circleElement = document.createElement("div");
                circleElement.className = "key-circle"
                circleElement.style.width = '16px';
                circleElement.style.height = '16px';
                circleElement.style.borderRadius = '50%';
                circleElement.style.background = entry.color;
                innerDiv.appendChild(circleElement);
            }

            keyDiv.appendChild(innerDiv);
        });

        return keyDiv;
    }

    return {
        render,
    };
}


export { Key }
