import {addAjaxPromise} from "../shared/ajax_promise";

function fetchSiteDefaultConnectionType(siteId) {
    const promise =  new Promise((resolve, reject) => {

        // Get chart data for each inspection
        $.ajax({
            type: "POST",
            url: "/api/site_default_connection_type",
            data: JSON.stringify({ site: siteId }),
            cache: false,
            contentType: "application/json",
            success: function (data) {
                resolve(data);
            },
            error: function (err) {
                resolve({ error: err });
            }
        });
    });

    addAjaxPromise(promise);

    return promise
};

export { fetchSiteDefaultConnectionType };