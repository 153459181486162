import {addAjaxPromise} from "../shared/ajax_promise";

function fetchBoltData(turbineId, connectionType) {
    const promise =  new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: "/api/bolt_bar_chart_data",
            data: JSON.stringify({ turbine: turbineId, connection: connectionType }),
            cache: false,
            contentType: "application/json",
            success: function (data) {
                resolve(data);
            },
            error: function (err) {
                resolve({ error: err });
            }

        });
    });

    addAjaxPromise(promise); // Add the AJAX promise to the array

    return promise;
};

export { fetchBoltData }