function SecondarySelectors() {
    function render() {
        const secondarySelectors = document.createElement('div')
        secondarySelectors.id = "secondary-selectors";
        secondarySelectors.className = "secondary-selectors"

        return secondarySelectors;
    }

    return {
        render,
    }
}

export { SecondarySelectors }