import {fetchBoltData} from "../ajax/fetch_bolt_data"
import {assembleBoltDataset} from "../connection/assemble_bolt_dataset";
import {repopulateSelectorOptions} from "../shared/_repopulate_selector_options";
import {updateSelector} from "../shared/update_selector";
import {clearElements} from "../_clear_elements"
import {handleAjaxErrors} from "../shared/ajax_promise";

function renderBoltChart(turbineIds, connectionType) {
  if (turbineIds.length != 1) {
    clearElements("bolt-bar-chart-div");
    return;
  }

  fetchBoltData(turbineIds[0], connectionType).then((ajaxData) => {
    var chartData = assembleBoltDataset(ajaxData);
    if (chartData != null) {
      loadBoltBarChart(chartData);
      repopulateSelectorOptions("inspection", chartData);
      var optionsToUpdate = {
        "All Inspections": {
          selected: true
        }
      };
      updateSelector('inspection', optionsToUpdate)
    }
  }).catch(handleAjaxErrors)
}

function loadBoltBarChart(chartData) {
    const multiCollapseDiv3 = document.getElementById('v-pills-bolt')

    // Check if the element already exists
  let boltBarChartDiv = document.getElementById("bolt-bar-chart-div");
  if (!boltBarChartDiv) {
    // If it doesn't exist, create it
    boltBarChartDiv = document.createElement("div");
    boltBarChartDiv.id = "bolt-bar-chart-div";
    boltBarChartDiv.className = "bolt-bar-chart-div";
    multiCollapseDiv3.appendChild(boltBarChartDiv);
  }

  // Empty the chart div
  for (let i = boltBarChartDiv.childNodes.length - 1; i >= 0; i--) {
    const childNode = boltBarChartDiv.childNodes[i];
    boltBarChartDiv.removeChild(childNode);
  }

  // Create Header area
  var boltBarChartHeaderDiv = document.createElement("div")
  boltBarChartHeaderDiv.id = "bolt-bar-chart-header-div"
  boltBarChartHeaderDiv.className = "chart-header-div"
  var header = document.createElement("h2")
  header.innerText = "Bolt Preload"
  var turbineNumber = document.createElement("p")
  turbineNumber.className = "bolt-bar-chart-sub-header"
  turbineNumber.innerText = "Turbine: " + chartData.overview.turbineName
  var connectionTypeField = document.createElement("p")
  connectionTypeField.className = "bolt-bar-chart-sub-header"
  connectionTypeField.innerText = "Connection: " + chartData.overview.connectionType
  var numberOfBolts = document.createElement("p")
  numberOfBolts.className = "bolt-bar-chart-sub-header"
  numberOfBolts.innerText = "No. of Bolts: " + chartData.data.datasets[0].bolt_ids.length
  boltBarChartDiv.appendChild(boltBarChartHeaderDiv)
  boltBarChartHeaderDiv.appendChild(header)
  boltBarChartHeaderDiv.appendChild(turbineNumber)
  boltBarChartHeaderDiv.appendChild(connectionTypeField)
  boltBarChartHeaderDiv.appendChild(numberOfBolts)

  if (chartData.overview.damaged_bolts && chartData.overview.damaged_bolts.length > 0) {
    var damagedBoltsDiv = document.createElement("div");
    damagedBoltsDiv.className = "bolt-bar-chart-sub-header";
    damagedBoltsDiv.innerText = "Damaged Bolts: #" + chartData.overview.damaged_bolts.join(", #");
    boltBarChartHeaderDiv.appendChild(damagedBoltsDiv);
  }

  // create custom Legend
  var customLegend = document.createElement("div")
  customLegend.id = "customLegend"
  customLegend.className = "chart-legend"
  boltBarChartDiv.appendChild(customLegend)

  // Create a new canvas
  var boltBarChartCanvas = document.createElement("canvas")
  boltBarChartCanvas.id = "bolt-bar-chart-canvas"
  boltBarChartCanvas.className = "w-100 bolt-bar-chart-canvas"
  boltBarChartDiv.appendChild(boltBarChartCanvas)


  // create chart
  var barChartData = chartData["data"]
  var thresholds = chartData["thresholds"]

  var boltBarChart = new Chart(boltBarChartCanvas, {
    type: 'bar',
    data: barChartData,
    options: {
      responsive: true,
      legend: {
        display: false
      },
      annotation: {
        annotations: [
          {
            borderColor: 'red',
            borderDash: [2, 2],
            borderWidth: 2,
            mode: 'horizontal',
            type: 'line',
            value: thresholds["min"],
            scaleID: 'y-axis-0',
            label: {
              content: "Min Load",
              enabled: true,
              position: "bottom",
              backgroundColor: 'transparent',
              // fontColor: '#e0e2e2',
              fontStyle: 'normal',
              yPadding: 3,
              xAdjust: -17
            }
          },
          {
            borderColor: 'red',
            borderDash: [2, 2],
            borderWidth: 2,
            mode: 'horizontal',
            type: 'line',
            value: thresholds["max"],
            scaleID: 'y-axis-0',
            label: {
              content: "Max Load",
              enabled: true,
              position: "bottom",
              backgroundColor: 'transparent',
              // fontColor: '#e0e2e2',
              fontStyle: 'normal',
              yPadding: 3,
              xAdjust: -17
            }
          },
          {
            borderColor: 'green',
            borderDash: [2, 2],
            borderWidth: 2,
            mode: 'horizontal',
            type: 'line',
            value: thresholds["target"],
            scaleID: 'y-axis-0',
            label: {
              content: "Target Load",
              enabled: true,
              position: "bottom",
              backgroundColor: 'transparent',
              // fontColor: '#e0e2e2',
              fontStyle: 'normal',
              yPadding: 3,
              xAdjust: -17
            }
          },
          {
            borderColor: 'green',
            borderDash: [2, 2],
            borderWidth: 2,
            mode: 'horizontal',
            type: 'line',
            value: thresholds["target"],
            scaleID: 'y-axis-0',
            label: {
              content: "Target Load",
              enabled: true,
              position: "bottom",
              backgroundColor: 'transparent',
              // fontColor: '#e0e2e2',
              fontStyle: 'normal',
              yPadding: 3,
              xAdjust: -17
            }
          },
          {
            borderColor: 'orange',
            borderDash: [2, 2],
            borderWidth: 2,
            mode: 'horizontal',
            type: 'line',
            value: thresholds["amber"],

            scaleID: 'y-axis-0',
            label: {
              content: "Amber Load",
              enabled: true,
              position: "bottom",
              backgroundColor: 'transparent',
              // fontColor: '#e0e2e2',
              fontStyle: 'normal',
              yPadding: 3,
              xAdjust: -17
            }
          }
        ]
      },
      scales: {
        xAxes: [{
          // type: 'linear',
          // position: 'bottom',
          // id: 'x-axis-0',
          // display: true,
          scaleLabel: {
               display: true,
               labelString: 'Bolt Number',
               fontColor: '#e8e8ee'
          },
          ticks: {
            fontColor: '#e8e8ee'  // Change color for x-axis ticks (scale values)
          }
        }],
        yAxes: [{
          id: 'y-axis-0',
          type: 'linear',
          position: 'left',
          ticks: {
            suggestedMin: 0,
            suggestedMax: thresholds["max"] * 1.01,
            fontColor: '#e8e8ee'
          },
          scaleLabel: {
            display: true,
            labelString: 'Load (kN)',
            fontColor: '#e8e8ee'
          }
        }]
      }
      //onClick: barChartClickEvent
    }
  });

  setInitialDatasetVisibility(boltBarChart, 1);
  generateCustomLegend(boltBarChart);

  function setInitialDatasetVisibility(chart, numberOfDatasets) {
    // Loop over all datasets
    chart.data.datasets.forEach((dataset, index) => {
      if (numberOfDatasets === 1) {
        chart.getDatasetMeta(index).hidden = index !== 0;
      } else if (numberOfDatasets >= 2) {
        chart.getDatasetMeta(index).hidden = index > 1;
      } else {
        chart.getDatasetMeta(index).hidden = true;
      }
    });
    // Update the chart to reflect the changes
    chart.update();
  }

  function generateCustomLegend(boltBarChart) {
    var customLegend = document.getElementById("customLegend");

    // Clear the existing legend
    while (customLegend.firstChild) {
      customLegend.removeChild(customLegend.firstChild);
    }

    boltBarChart.data.datasets.forEach(function(dataset, datasetIndex) {
      if (!boltBarChart.getDatasetMeta(datasetIndex).hidden) {  // Check if the dataset is visible
        var label = dataset.label;
        var backgroundColor = dataset.backgroundColor;
        var averageLoad = dataset.averageLoad;

        var legendItem = document.createElement('div');
        legendItem.classList.add('legend-item');

        var colorBox = document.createElement('span');
        colorBox.classList.add('color-box');
        colorBox.style.backgroundColor = backgroundColor;
        legendItem.appendChild(colorBox);

        var labelSpan = document.createElement('span');
        labelSpan.innerText = label + " ("+ averageLoad + "kN)";
        legendItem.appendChild(labelSpan);

        var downloadButton = document.createElement('i');
        downloadButton.className = "material-icons inspection-download-button";
        downloadButton.innerText = "cloud_download";
        var inspectionId = dataset.id; // Replace with your inspection ID
        downloadButton.setAttribute('data-inspection-id', inspectionId);

        customLegend.appendChild(legendItem);
        legendItem.appendChild(downloadButton);
      }
    });
  }

  $(document).ready(function () {
    $('#inspection-selector').on('change', function() {
      // Get the selected options
      var selectedOptions = $(this).val();

      // Loop over all datasets
      boltBarChart.data.datasets.forEach(function(dataset, index) {
        // If the dataset's label is in the selected options, show it, otherwise hide it
        boltBarChart.getDatasetMeta(index).hidden = !selectedOptions.includes(dataset.label);
      });

      // Update the chart and legend to reflect the changes
      boltBarChart.update();
      generateCustomLegend(boltBarChart);
    });
  });
}

export { renderBoltChart };