import {createMapDataset} from "../_create_map_dataset";
import {assembleSiteDataset} from "../_assemble_site_dataset";
import {assembleFleetDataset} from "../_assemble_fleet_dataset";
import {clearMapLayers, clearLayers} from "../shared/clear_map_Layers";
import {fetchMapFleetData} from "../ajax/fetch_map_fleet_data";
import {fetchMapSiteData} from "../ajax/fetch_map_site_data";
import {state} from "../shared/state";
import {handleAjaxErrors} from "../shared/ajax_promise";

function renderMap(stateChangeSummary) {
    if (stateChangeSummary.includes("fleet") || stateChangeSummary.includes("site")) {
        let fetchDataPromise;

        if (state.site === null) {
            var layerName = "fleet"
            fetchDataPromise = fetchMapFleetData(state.fleet).then((ajaxData) => ajaxData);
        } else if (state.site != null) {
            var layerName = "site"
            fetchDataPromise = fetchMapSiteData(state.site).then((ajaxData) => ajaxData);
        }

        fetchDataPromise
            .then((data) => createMap(data, layerName))
            .catch(handleAjaxErrors);
    }
}

function createMap(ajaxData, layerName) {
    // I think the wrong assemble dataset is being called here....
    let chartData;
    if (layerName === "fleet") {
        chartData =  ajaxData  //assembleFleetDataset(ajaxData);
    } else
    if (layerName === "site")  {
        chartData = assembleSiteDataset(ajaxData);
    };

    var mapData = createMapDataset(chartData);

    // clear map layers
    if (layerName === "fleet" || layerName === "site") { var layersToClear = ["fleet", "site"] }

    if (map.loaded()) {
        clearLayers(layersToClear)
        addMapSourceAndLayer();
    } else {
        // addMapSourceAndLayer();
        map.on('load', () => {
            const allLayers = map.getStyle().layers.map((layer) => layer.id);
            if (!allLayers.includes(layerName)) {
                clearMapLayers(layersToClear)
                addMapSourceAndLayer();
            }
        });
    }
    ;

    function addMapSourceAndLayer() {
        // Add a GeoJSON source
        map.addSource(layerName, {
            'type': 'geojson',
            'data': mapData
        });

        const coordinates = extractCoordinates(mapData);
        const boundingBox = calculateBoundingBox(coordinates);
        // set padding so that overlays do not obscure map points;
        if (layerName === "site") {
            var paddingLeft = window.innerWidth * 0.72
        } else if (layerName === "fleet") {
            var paddingLeft = window.innerWidth * 0.65
        }
        var paddingBottom = 700

        map.fitBounds(boundingBox, {
            padding: { top: 0, bottom: paddingBottom, left: paddingLeft, right: 100 },
            linear: true,
            pitch: 60
        });

        // Add a layer referencing the GeoJSON source
        map.addLayer({
            id: layerName,
            type: 'circle',
            source: layerName,
            paint: {
                'circle-color': ['get', 'color'],
                'circle-radius': 8
            }
        });
    }

    function extractCoordinates(geojsonData) {
        const coordinates = [];
        geojsonData.features.forEach((feature) => {
            if (feature.geometry.type === "Point") {
                coordinates.push(feature.geometry.coordinates);
            }
        });
        return coordinates;
    }

// calculate bounding box
    function calculateBoundingBox(coordinates) {
        let minLng = Infinity;
        let minLat = Infinity;
        let maxLng = -Infinity;
        let maxLat = -Infinity;

        coordinates.forEach((coord) => {
            if (coord[0] < minLng) minLng = coord[0];
            if (coord[0] > maxLng) maxLng = coord[0];
            if (coord[1] < minLat) minLat = coord[1];
            if (coord[1] > maxLat) maxLat = coord[1];
        });

        return [[minLng, minLat], [maxLng, maxLat]];
    }
}


export { renderMap }