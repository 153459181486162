//import { addAjaxPromise } from "../shared/ajax_promise";

function downloadInspectionCSV(inspectionId) {
    const promise = new Promise((resolve, reject) => {
        $.ajax({
            type: "POST",
            url: `/api/inspection_csv_loads_only`,
            data: JSON.stringify({ id: inspectionId}),
            contentType: "application/json",
            success: function (response, status, xhr) {
                // Extract filename from the 'Content-Disposition' header
                var contentDisposition = xhr.getResponseHeader('Content-Disposition');
                var match = /filename="(.+)"/.exec(contentDisposition);
                var filename = match && match[1] ? decodeURIComponent(match[1]) : 'default_filename.csv';

                // Create a blob URL and trigger download
                var blob = new Blob([response], { type: 'text/csv' });
                var downloadLink = document.createElement('a');
                downloadLink.href = URL.createObjectURL(blob);
                downloadLink.download = filename;
                document.body.appendChild(downloadLink); // Append to body to ensure visibility in Firefox
                downloadLink.click();
                document.body.removeChild(downloadLink); // Clean up

                resolve();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log('Error: ', errorThrown);
                reject(errorThrown);
            }
        });
    });

    //addAjaxPromise(promise); // Add the AJAX promise to the array

    return promise;
};

export { downloadInspectionCSV };