import {state} from "./state"

function initialTableFilter() {
    if (state.connection != "All Connections" && state.connection != null) {
        return;
    }
    // Fetch the DataTable instance
    var dataTable = $('#connectionStatusTable').DataTable();

    // Set only the 'Summary' row as visible
    //dataTable.column('connection:name').search('Summary').draw();

    // Get the summary row data
    var summaryRow = dataTable.row(function(idx, data, node) {
        return data.connection === 'Summary';
    }).data();

    var columnsToHide = [];

    // Iterate through each column to find those with 'light' status
    dataTable.columns().every(function(index) {
        // Skip the first column (connection column)
        if (index === 0) return;

        // Get the column name (like "T01", "T02", ...)
        var columnName = this.header().innerHTML;

        // If the status is 'light', add the column index to the list of columns to hide
        if (summaryRow[columnName] && summaryRow[columnName].status === "light" || summaryRow[columnName] && summaryRow[columnName].status === null) {
            columnsToHide.push(index);
        }
    });

    // Hide all columns with 'light' status in one operation
    dataTable.column('connection:name').search('^((?!Summary).)*$', true, false).draw();
    dataTable.columns(columnsToHide).visible(false);
}

export { initialTableFilter };
