function activateTab(tabName) {
    // Deactivate all tabs
    document.querySelectorAll('.nav-link').forEach(function(tab) {
        tab.classList.remove('active');
    });

    document.querySelectorAll('.tab-pane').forEach(function(tabPane) {
        tabPane.classList.remove('show', 'active');
    });

    // Activate the specified tab
    var tabPane = document.querySelector(`#v-pills-${tabName}`);
    var tabLink = document.querySelector(`a[href="#v-pills-${tabName}"]`);

    if (tabPane && tabLink) {
        tabPane.classList.add('show', 'active');
        tabLink.classList.add('active');
    }
}

export {activateTab}