function updateSelector(assetSelector, optionsToUpdate) {
    var select = document.getElementById(assetSelector + "-selector");
    if (!select) { return }

    var options = select.options;

    for (var i = 0; i < options.length; i++) {
        var option = options[i];
        var optionText = option.innerText;

        if (optionsToUpdate.hasOwnProperty(optionText)) {
            var updateData = optionsToUpdate[optionText];

            if (updateData.hasOwnProperty("selected")) {
                option.selected = updateData.selected;
            }

            if (updateData.hasOwnProperty("disabled")) {
                option.disabled = updateData.disabled;
            }
        }
    }

    if (assetSelector === "inspection") {
        var selectElement = $('#inspection-selector');
        selectElement[0].sumo.reload();
    } else if (assetSelector === "status") {
        var selectElement = $('#status-selector');
        selectElement[0].sumo.reload();
    }
}

export { updateSelector }