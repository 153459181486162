import { createSelectOptions } from "./_create_select_options";
import {assembleSelectorData} from "./_assemble_selector_data";
import {setMultiSelectorSelected, setSelectorSelected} from "./set_selector_selected";
import {state} from "./state";

function repopulateSelectorOptions(asset, ajaxData) {
  var optionsArray = assembleSelectorData(asset, ajaxData)
  var selectorDiv = document.getElementById(asset + "-selector")
  // clear existing options if any
  if (!selectorDiv) { return }

  for (let i = selectorDiv.childNodes.length - 1; i >= 0; i--) {
    const childNode = selectorDiv.childNodes[i];
   // if (childNode !== "") {
    selectorDiv.removeChild(childNode);
   // }
   }

  createSelectOptions(selectorDiv, optionsArray);
  if (asset === "turbine") {
    if (state.turbine != "All Turbines" && state.turbine.length != 0) {
      setMultiSelectorSelected('turbine-selector', state.turbine)
      $('#turbine-selector')[0].sumo.reload();
    } else {
      $('#turbine-selector')[0].sumo.reload();
      $('#turbine-selector')[0].sumo.selectAll();
    }
  } else if (asset === "connection") {
    setSelectorSelected('connection-selector', state.connection)
    $('#connection-selector')[0].sumo.reload();
  }
}

export { repopulateSelectorOptions }