function colorHex(colorString) {
    if (!colorString || typeof colorString !== 'string') {
        return '#F7F7F9';
    }

    switch (colorString.toLowerCase()) {
        case 'danger':
            return '#E54B4B';
        case 'warning':
            return '#FCBF49';
        case 'success':
            return '#45CE63';
        case 'primary':
            return '#22ADF6';
        case 'light':
            return '#F7F7F9';
        default:
            return '#F7F7F9';
    }
}

export { colorHex };
