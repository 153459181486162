function assembleRelaxationDataset(chartData) {
    var dataSets = []
    var overview = chartData["overview"]


    for (let turbine of chartData.datasets) {
        if (turbine.data.length > 0)  {
            let dataset = {
                pointStyle: 'circle', // rectRot
                pointRotation: 90,
                pointBorderWidth: 2,
                pointRadius: 4,
                borderColor: turbine.turbine_color,
                backgroundColor: turbine.turbine_color,
                label: turbine.turbine_label,
                data: turbine.data
            }
            dataSets.push(dataset)
        }
    }
    return {
        dataSets: dataSets,
        overview: overview
    }
}

export { assembleRelaxationDataset }