import { createElementWithClass } from "./_create_element_with_class";

var DIV = "div";
var BUTTON = "button";
var SPAN = "span";
var P = "p";
var A = "a";

function initSteppedProgress() {
    [].forEach.call(
        document.querySelectorAll("[data-stepped-bar]"),
        function (steppedProgress, index) {
            if (steppedProgress) {
                var valueTotal = 0;
                var data;
                if (steppedProgress.getAttribute("data-stepped-bar")) {
                    data = JSON.parse(steppedProgress.getAttribute("data-stepped-bar"));
                } else {
                    data = defaults;
                }

                var title = createElementWithClass(P, "syncro-card-title");
                title.textContent = data.title;

                var step = createElementWithClass(DIV, "syncro-progress-stepped");

                data.catagories.forEach(function (catagory, i) {
                    valueTotal += catagory.value;
                });

                data.catagories.forEach(function (catagory, i) {
                    var stepItem = createElementWithClass(DIV, "syncro-progress-stepped-item");
                    stepItem.setAttribute(
                        "data-id",
                        "progress-stepped-item-" + index + "-" + i
                    );
                    stepItem.textContent = catagory.value;
                    stepItem.style.width = (catagory.value / valueTotal) * 100 + "%";
                    stepItem.style.backgroundColor = catagory.color;

                    step.appendChild(stepItem);

                    var dot = createElementWithClass(SPAN, "syncro-dot");
                    dot.style.backgroundColor = catagory.color;

                    var category = createElementWithClass(SPAN, "syncro-category-name");
                    category.textContent = catagory.name;
                });

                var cardBody = createElementWithClass(DIV, "syncro-card-body");
                cardBody.appendChild(step);

                var card = createElementWithClass(DIV, "syncro-card");
                card.appendChild(cardBody);

                var markup = createElementWithClass(DIV);
                markup.appendChild(card);

                steppedProgress.innerHTML = markup.innerHTML;

                [].forEach.call(
                    steppedProgress.querySelectorAll(".syncro-btn"),
                    function (el) {
                        el.addEventListener("click", function () {
                            const dataID = el.getAttribute("data-target");
                            var targetElm = document.querySelector(
                                '[data-id="' + dataID + '"]'
                            );

                            if (targetElm.classList.contains("active")) {
                                targetElm.classList.remove("active");
                            } else {
                                [].forEach.call(
                                    steppedProgress.querySelectorAll(
                                        ".syncro-progress-stepped-item"
                                    ),
                                    function (el) {
                                        el.classList.remove("active");
                                    }
                                );
                                targetElm.classList.add("active");
                            }
                        });
                    }
                );
            }
        }
    );
}

export { initSteppedProgress };