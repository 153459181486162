// router.js
import Navigo from 'navigo';
import {state, updateState} from "./state";

let router = new Navigo('');

router.on({
    '/': function () {
        if (window.APP_DATA) {
            router.navigate(`fleets/${window.APP_DATA.fleetId}`);
        } else {
            router.navigate('error');
        }
    },
    'fleets/:id': function (params) {
        var fleetId = params.data.id
        updateState({
            fleet: fleetId,
            site: null,
            connection: "All Connections",
            turbine: [],
            status: "All Status Levels",
            inspection: null});
    },
    'fleets/:fleetId/sites/:siteId': function (params) {
        var fleetId = params.data.fleetId
        var siteId = params.data.siteId
        updateState({
            fleet: fleetId,
            site: siteId,
            connection: "All Connections",
            turbine: [],
            status: "All Status Levels",
            inspection: null});
    },
    'fleets/:fleetId/sites/:siteId/connection_type/:connectionType': function (params) {
        const fleetId = params.data.fleetId
        const siteId = params.data.siteId;
        const connectionType = params.data.connectionType;

        updateState({
            site: siteId,
            fleet: fleetId,
            connection: connectionType,
            status: "All Status Levels"
        });
    },
    'fleets/:fleetId/sites/:siteId/connection_type/:connectionType/turbines/:turbineIds': function (params) {
        
        const fleetId = params.data.fleetId;
        const siteId = params.data.siteId;
        const connectionType = params.data.connectionType;

        const turbineData = params.data.turbineIds;
        if (turbineData === "All Turbines") {
            var turbineIds = []
        } else {
            var turbineIds = turbineData.split(',').map(Number);
        }
        
        //var status = state.status;
        //if (status === "Turbine Summary" && connectionType === "All Connections") {
        //    status = "All Inspected Turbines"
        //} else if (connectionData === "Summary") {
        //    status = "Turbine Summary"
        //}

        var statusSelectedTurbines = state.statusSelectedTurbines
        if (statusSelectedTurbines && turbineIds.sort().join(", ") != statusSelectedTurbines.sort().join(", ")) {
            var status = "All Status Levels"
            var statusSelectedTurbines = null
        } else {
            var status = state.status
            var statusSelectedTurbines = state.statusSelectedTurbines
        }

        updateState({
            fleet: fleetId,
            site: siteId,
            turbine: turbineIds,
            connection: connectionType,
            status: status,
            statusSelectedTurbines: statusSelectedTurbines
        });
    },
    '/error': function () {
    // Display error page
    }
});

export { router };
