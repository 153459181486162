function Sidebar() {
    function render() {
        const sidebar = document.createElement("div");
        sidebar.id = "boltwatch-sidebar";
        sidebar.className = "d-flex boltwatch-sidebar pr-0 position-absolute"

        return sidebar;
    }

    return {
        render,
    };
}

function SidebarCard() {
    function render() {
        const sidebarCard = document.createElement("div");
        sidebarCard.id = "sidebar-card";
        sidebarCard.className = "card sidebar-card border-0";

        return sidebarCard;
    }

    return {
        render,
    };
}

function SidebarCardBody() {
    function render() {
        const sidebarCardBody = document.createElement("div");
        sidebarCardBody.id = "sidebar-card-body";
        sidebarCardBody.className = "card-body p-0 d-flex";

        return sidebarCardBody;
    }

    return {
        render,
    };
}

function SidebarTabDiv() {
    function render() {
        const sidebarTabDiv = document.createElement("div");
        sidebarTabDiv.id = "sidebar-tab-div";
        // sidebarTabDiv.className = "col-md-2";

        return sidebarTabDiv;
    }

    return {
        render,
    };
}

function SidebarTabContentDiv() {
    function render() {
        const tabContentDiv = document.createElement("div");
        tabContentDiv.id = "tab-content-div";
        tabContentDiv.className = "w-100" // "col-md-10 d-flex flex-wrap";

        return tabContentDiv;
    }

    return {
        render,
    }
}

const tabNames = ['Fleet', 'Site', 'Relaxation', 'Bolt', 'Comments'];
const iconNames = ['public','location_on', 'show_chart', 'equalizer', 'comment']; // TODO: refactor these into a hash

function NavTabs() {
    let navLinks = tabNames;
    let navElement = document.createElement('div');
    navElement.className = 'nav flex-column nav-pills'; // flex-column
    navElement.id = 'myTab';
    navElement.role = 'tablist';
    navElement.setAttribute('aria-orientation', 'vertical');

    navLinks.forEach((navLink, index) => {
        let aElement = document.createElement('a');
        aElement.className = 'px-2 py-3 nav-link';
        aElement.type = 'button';
        if(index === 0){
            aElement.className += ' active';  // Set the first tab as active by default
        }
        aElement.id = `${navLink.toLowerCase().replace(/\s/g, '-')}-tab`;
        aElement.setAttribute('data-toggle', 'pill');
        aElement.href = `#v-pills-${navLink.toLowerCase().replace(/\s/g, '-')}`;
        aElement.role = 'tab';
        aElement.setAttribute('aria-controls', navLink.toLowerCase().replace(/\s/g, '-'));
        aElement.setAttribute('aria-selected', 'false');


        // Create icon container
        let iconContainer = document.createElement('div');
        iconContainer.className = 'icon-container d-flex flex-column align-items-center justify-content-center';

        // Create and add icon to icon container
        let icon = document.createElement('i');
        icon.className = 'material-icons';
        icon.textContent = iconNames[index]; // you may need to change this if icon names are not directly corresponding with tab names
        iconContainer.appendChild(icon);

        // Create and add text to icon container
        let text = document.createElement('p');
        text.textContent = navLink;
        text.className = "m-0"
        iconContainer.appendChild(text);

        // Append icon container to link element
        aElement.appendChild(iconContainer);

        navElement.appendChild(aElement);
    });

    return {
        render: function () {
            return navElement;
        }
    };
}


function TabPanes() {
    let panes = tabNames;
    let tabContent = document.createElement('div');
    tabContent.className = 'tab-content w-100';
    tabContent.id = 'v-pills-tabContent';

    panes.forEach((pane, index) => {
        let divElement = document.createElement('div');
        divElement.className = 'tab-pane fade';
        if(index === 0){
            divElement.className += ' show active';  // Set the first tab-pane as active by default
        }
        divElement.id = `v-pills-${pane.toLowerCase().replace(/\s/g, '-')}`; //  v-pills-
        divElement.role = 'tabpanel';
        divElement.setAttribute('aria-labelledby', `v-pills-${pane.toLowerCase().replace(/\s/g, '-')}-tab`);

        tabContent.appendChild(divElement);

        if (pane === "Site") {
            let tabHeader = document.createElement('div');
            tabHeader.className = 'd-flex';
            tabHeader.id = "site-tab-header"

            divElement.appendChild(tabHeader)
        }
    });

    return {
        render: function () {
            return tabContent;
        }
    };
}

function SidebarLabel(text) {
    function render() {
        const sidebarLabel = document.createElement("div");
        sidebarLabel.className = "sidebar-label"
        sidebarLabel.id = "sidebar-label-" + text;
        sidebarLabel.textContent = text;

        return sidebarLabel;
    }

    return {
        render,
    }
}

export { Sidebar, SidebarCard, SidebarCardBody, SidebarTabDiv, SidebarTabContentDiv, NavTabs, TabPanes, SidebarLabel }