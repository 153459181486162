function createSelector(id, type, placeholder) {
    const selector = document.createElement("select");
    selector.className = "sortby-dropdown border-0 text-center font-weight-bolder"; //mt-3 m-2";
    selector.id = id;
    if (type === "multiple") {
        selector.multiple = "multiple"
    }

    if (placeholder) {
        selector.placeholder = placeholder
    }

    // Add CSS classes based on the id parameter
    if (id === "site-selector" || id === "fleet-selector") {
        selector.classList.add("primary-selector-dropdown");
    } else {
        selector.classList.add("secondary-selector-dropdown");
    }

    if (id === "inspection-selector") {
        // initalize using SumoSelector for multiselect
        selector.classList.add("SlectBox")
    }

    return selector
}

export { createSelector }