function clearMapLayers(layerIds) {
    
    if (map.loaded()) {
        
        clearLayers(layerIds);
    } else {
        map.on('load', () => {
            
            clearLayers(layerIds);
        });
    }
}

function clearLayers(layerIds) {
    const style = map.getStyle();

    layerIds.forEach((layerId) => {
        const layer = style.layers.find((layer) => layer.id === layerId);

        if (layer) {
            map.removeLayer(layer.id);
            map.removeSource(layer.id);
        }
    });
}

export { clearMapLayers, clearLayers };
