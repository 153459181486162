import {filterColumnsBySeverityRange} from "../site/filter_columns_by_serverity_rating";
import {state} from "../shared/state"

function renderSiteOverviewFilter(stateChangeSummary) {
    if (stateChangeSummary.includes('status') || stateChangeSummary.includes('turbine') || stateChangeSummary.includes('connection') || stateChangeSummary.includes('site')) {
      filterColumnsBySeverityRange()
  }
}

export { renderSiteOverviewFilter }

