import {state} from "../shared/state"
import {router} from "../shared/router";
import {flashMessage} from "../shared/flash_message"
import {fetchSiteDefaultConnectionType} from "../ajax/fetch_site_default_connection_type";
import {getAllAjaxPromises, handleAjaxErrors, turnOffLoader, turnOnLoader} from "../shared/ajax_promise";
import {activateTab} from "../shared/activate_tab";
import {renderSelectors} from "../render/render_selectors";
import {renderSiteOverviewFilter} from "../render/render_site_overview_filter";

//let preventTabChange = false;

$(document).ready(function() {
    // Event listener for tab link clicks
    $(document).on('show.bs.tab', '.nav-link', function(event) {
        var tabId = $(this).attr('href');
        var tabName = tabId.substring("#v-pills-".length);

        if (tabAvailable(tabName)) {
           standardRoute(tabName);
        } else {
            event.preventDefault(); // Prevent changing the tab
            showRouteSelectionPopup(tabName);
        }
    });

    $(document).on('hidden.bs.modal', '#routeSelectionModal', function() {
        $(this).remove();
    });

});

function showRouteSelectionPopup(tabName) {
    // Fetch the required condition for the tab
    let condition = getTabCondition(tabName);

    // Create the pop-up content
    let message = `${condition}`;
    let popupHtml = `
        <div class="modal fade" id="routeSelectionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Tab Selection</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        ${message}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">OK</button>
                        <button type="button" class="btn btn-primary" id="setSeverest">Select highest priority</button>
                    </div>
                </div>
            </div>
        </div>
    `;

    // Append the pop-up to the body or any other suitable container
    $('#main-content-row').append(popupHtml);

    // Trigger the modal
    $('#routeSelectionModal').modal('show');

    // Event listener for the buttons
    $('#setSeverest').on('click', function() {
        turnOnLoader();
        defaultRoute(tabName);
        Promise.all(getAllAjaxPromises()).then((results) => {
            activateTab(tabName);
        }).catch(handleAjaxErrors);
        $('#routeSelectionModal').modal('hide');
    });
    $('#pickOwn').on('click', function() {
        $('#routeSelectionModal').modal('hide');
    });
}

function getTabCondition(tabName) {
    // You can further define conditions for each tab here.
    // This is just a mock example.
    let conditions = {
        "fleet": "Please select one fleet.",
        "site": "Please select one site.",
        "relaxation": "Please select one connection type.",
        "bolt": "Please select one turbine and connection type.",
        "comments": "Please select one site."
    };
    return conditions[tabName] || "Please select resources.";
}

function defaultRoute(tabName) {
    if (tabName === "site") {
        var defaultSite = getSeverestSiteID();
        router.navigate(`fleets/${state.fleet}/sites/${defaultSite}`)
    } else if (tabName === "relaxation") {
        if (state.site === null) {
            var siteId = getSeverestSiteID();
        } else {
            var siteId = state.site
        }
        fetchSiteDefaultConnectionType(siteId).then((ajaxData) => {
            var connectionType = encodeURIComponent(ajaxData.connection_type);
            router.navigate(`fleets/${state.fleet}/sites/${siteId}/connection_type/${connectionType}`)
            var message = "Default connection type selected"
            //flashMessage("v-pills-relaxation", message);
        }).catch((error) => {
            // Handle the error here
            console.log("an error occurred", error);
        });
    } else if (tabName === "bolt") {
        if (state.site === null) {
            var siteId = getSeverestSiteID();
        } else {
            var siteId = state.site
        }
        fetchSiteDefaultConnectionType(siteId).then((ajaxData) => {
            // TODO: make this more nuanced
            var connectionType = encodeURIComponent(ajaxData.connection_type);
            var defaultTurbineId = encodeURIComponent(ajaxData.turbine);
            router.navigate(`fleets/${state.fleet}/sites/${siteId}/connection_type/${connectionType}/turbines/${defaultTurbineId}`)
            var message = "Default turbine and connection type selected"
            //flashMessage("v-pills-bolt", message);
        }).catch((error) => {
            // Handle the error here
            console.log("an error occurred", error);
        });
    } else if (tabName === "comments") {
        var defaultSite = getSeverestSiteID();
        router.navigate(`fleets/${state.fleet}/sites/${defaultSite}`)
    }
}

function getSeverestSiteID() {
    var dataTable = $('#siteTable').DataTable();

    var lowestOrder = Number.POSITIVE_INFINITY;
    var lowestSiteId = null;
    var tolerance = 0.0001; // Adjust the tolerance value as needed

    dataTable.rows().every(function () {
        var rowData = this.data();
        var connectionStatusOrder = parseFloat(rowData.connectionStatusOrder);
        var SiteId = rowData.id;


        if (connectionStatusOrder < lowestOrder - tolerance) {
            lowestOrder = connectionStatusOrder;
            lowestSiteId = SiteId;
        }
    });

    return lowestSiteId;
}

function tabAvailable(tabName) {
    if (tabName === "fleet") {
        if (state.fleet != null) {
            return true;
        } else {
            return false;
        }
    } else if (tabName === "site") {
        if (state.site != null) {
            return true;
        } else {
            return false;
        }
    } else if (tabName === "relaxation") {
        if (state.connection != "All Connections" && state.connection != null && state.turbine.length > 0) {
            return true;
        } else {
            return false;
        }
    } else if (tabName === "bolt") {
        if (state.turbine.length === 1 && state.connection != null && state.connection != "All Connections") {
            return true;
        } else {
            return false;
        }
    } else if (tabName === "comments") {
        if (state.site != null) {
            return true;
        } else {
            return false;
        }
    }
}

function standardRoute(tabName) {
    if (tabName === "fleet") {
        router.navigate(`/fleets/${state.fleet}`);
    } else if (tabName === "site") {
         if (state.connection != null) {
            router.navigate(`fleets/${state.fleet}/sites/${state.site}/connection_type/${state.connection}`)
        } else {
            router.navigate(`fleets/${state.fleet}/sites/${state.site}`)
        }
    } else if (tabName === "relaxation") {
        router.navigate(`fleets/${state.fleet}/sites/${state.site}/connection_type/${state.connection}`)
    } else if (tabName === "bolt") {
        router.navigate(`fleets/${state.fleet}/sites/${state.site}/connection_type/${state.connection}/turbines/${state.turbine}`)
    } else if (tabName === "comments") {
        return;
    };
};


