function siteTabContent() {

    //const row = document.getElementById("main-content-row"); // this contains the map
    const siteTabContent = document.getElementById("v-pills-site")
    //siteTabContent.empty(); // TODO: remove this when it becomes obsolete

    // create the tab content div
    const tabContentDiv = document.createElement("div");
    tabContentDiv.id = "site-overview-card";
    tabContentDiv.className = "card site-overview-card border-0";
    siteTabContent.appendChild(tabContentDiv);

    const connectionStatusTableCanvas = document.createElement("div");
    connectionStatusTableCanvas.className = "mr-0 hover w-100"; // Replace "col-md-11" with "w-100"
    connectionStatusTableCanvas.id = "connection-status-table-canvas";
    tabContentDiv.appendChild(connectionStatusTableCanvas);
}

export { siteTabContent }