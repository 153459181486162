import {repopulateSelectorOptions} from "../../shared/_repopulate_selector_options";
import {updateSelector} from "../../shared/update_selector";
import {state} from "../../shared/state";

function renderInspectionSelector(statusChangeSummary) {
    let singleConnection = (state.connection != "All Connections" && state.connection != null)
    let multiConnection = (state.connection === "All Connections" || state.connection === null)
    let singleTurbine = state.turbine.length === 1
    let multipleTurbines = state.turbine.length != 1

    if (singleConnection && multipleTurbines) {
        if (checkMultiSelectEnabled()) {
            // if multiselect enabled
           disableMultiSelect()
        }

        repopulateSelectorOptions("inspection", "default");
        // update inspection dropdown to be "all inspections"
        var optionsToUpdate = {
            "All Inspections": {
                selected: true,
                disabled: false
            },
            "Latest Inspection": {
                selected: false,
                disabled: true
            }
        };
        updateSelector('inspection', optionsToUpdate)

        // selectOption('inspection', 'All Inspections', false)

    } else if (singleConnection && singleTurbine) {
        // it becomes multiselect ...
        // it gets updated in boltChart by repopulateSelectorOptions("inspection", chartData);

        var optionsToUpdate = {
            "All Inspections": {
                disabled: false
            }
        };
        enableMultiSelect();
        updateSelector('inspection', optionsToUpdate)

    } else if (multiConnection) {
        if (checkMultiSelectEnabled()) {
            // if multiselect enabled
            disableMultiSelect()
        }

        repopulateSelectorOptions("inspection", "default")

        var optionsToUpdate = {
            "All Inspections": {
                disabled: true
            },
            "Latest Inspection": {
                selected: true
            }
        };
        updateSelector('inspection', optionsToUpdate)
    }

}

function enableMultiSelect() {
    $('#inspection-selector').attr('multiple', 'multiple');
    $('#inspection-selector')[0].sumo.unload(); // Enable multiple attribute
    $('#inspection-selector').SumoSelect({
        //okCancelInMulti: true,
        selectAll: true,
        placeholder: "Select Inspection(s)",
        captionFormatAllSelected: "All Inspections",
        csvDispCount: 2,
        captionFormat: '{0} Inspections Selected'
    }); //
    $('#inspection-selector')[0].sumo.remove(0);
    try {
        $('#inspection-selector')[0].sumo.selectItem(0);
    } catch {
        return
    }
}

function disableMultiSelect() {
    var selectElement = document.getElementById('inspection-selector');
    selectElement.removeAttribute('multiple');
    $('#inspection-selector')[0].sumo.unload(); // Enable multiple attribute
    $('#inspection-selector').SumoSelect();
}

function checkMultiSelectEnabled() {
    var selectElement = document.getElementById('inspection-selector');
    if (selectElement.multiple) {
        return true
    } else {
        return false
    }
}

export {renderInspectionSelector}